import {Button} from 'react-bootstrap';
import chevronBackward from 'assets/icons/chevron-circle-right@3x.png';


export default function ButtonAbort({setShowModal}) {

  const handleOnButtonClick = () => {
    setShowModal(true);
  };

  return (
          <Button variant='light' onClick={handleOnButtonClick}>
            <img className='chevron-backward' src={chevronBackward} alt='Digitalisierungs-Check beenden' />
            Digitalisierungs-Check beenden
          </Button>
      );
}
