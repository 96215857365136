import './TopSolutionPartnerList.scss';
import { Col, Row } from 'react-bootstrap';
import { Component } from 'react';
import TopSolutionPartnerItem from './TopSolutionPartnerItem';



export default class TopSolutionPartnerList extends Component<ISolutionPartnerListProps> {

  renderTopSolutionPartners() {
    const { questionnaire, numberTopSolutionPartners } = this.props;

    if (questionnaire.solutionPartnerRecommendations === undefined) {
      return (null);
    }

    return questionnaire.solutionPartnerRecommendations.map((solutionPartner, index) => {
      if (index >= numberTopSolutionPartners) {
        return (null);
      }

      return (
        <TopSolutionPartnerItem key={solutionPartner.uuid} solutionPartner={solutionPartner} onFavoriteChange={this.props.onFavoriteChange}
        onPartnerClick={this.props.onPartnerClick}/>
      );
    });
  }

  render() {
    return (
      <div className='result-top-solution-partner-wrapper pagebreak'>
        <Row className='result-top-solution-partner-content'>
          <Col>
            <h1>Unsere Handlungsoptionen für Sie</h1>
            <h2>TOP Empfehlungen für Sie</h2>
            <p>
              Die im Folgenden dargestellten Unternehmen bieten Produkte und digitale
              Lösungen für Ihre relevanten Handlungsoptionen und Themen an.
            </p>
          </Col>
        </Row>
        <Row className='result-top-solution-partner-wrapper'>
          { this.renderTopSolutionPartners() }
        </Row>
      </div>
    );
  }
}
