import {Component} from 'react';
import './Steps.scss';

export default class Steps extends Component<ICategoryProps> {
    render() {
        return (
            <div className='box-steps'>
                <img src={this.props.imgUrl} alt={this.props.imgUrl}/> <br/>
                {this.props.title.split(/\s+/)[0]}<br/>
                {this.props.title.split(/\s+/)[1]}
                {this.props.title === 'Digitalisierungsberater kontaktieren' ? '' : <div>
                    <div className='inner-arrow'/>
                    <div className='outer-arrow'/>
                </div>}
            </div>
        );
    }
}
