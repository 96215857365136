import '../Error.scss';
import notFound from 'assets/images/404@3x.png';
import ButtonRed from 'components/common/button/ButtonRed';
import AuthContext from 'context/AuthContext/authContext';
import {useContext} from 'react';


export default function NotFound() {
  const {authenticated} = useContext(AuthContext);
  const renderButton = () => {
    if (authenticated) {
      return (
        <ButtonRed url={'/profile'} content={'Zurück zum Profil'}></ButtonRed>
      );
    }

    return (
      <ButtonRed url={'/start'} content={'Zurück zum Start'}></ButtonRed>
    );
  };
  return (
      <div className={'mt-2 static-page-wrapper error-wrapper text-center'}>
        <img height={400} src={notFound} alt='' />
        <h1 className={'h1 error-header'}>Hoppla! Etwas ist schiefgelaufen.</h1>
        <div className={'error-description'}>
          Die von Ihnen angeforderte Seite konnte leider nicht gefunden werden.
          Klicken Sie den Button, um wieder auf die Startseite zu gelangen.
        </div>
        {renderButton()}
      </div>
    );
}
