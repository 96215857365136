import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Component } from 'react';
import chevronBackward from 'assets/icons/chevron-circle-right@3x.png';


export default class ButtonTransparent extends Component<IButtonProps> {
  getClassNames() {
    return this.props.summary ? 'default-button too-wide-button' : 'default-button';
  }

  render() {
    return (
      <Link to={this.props.url} className='mr-md-3'>
        <Button variant='info' className={this.getClassNames()}>
          <img className='chevron-backward' src={chevronBackward} alt={this.props.content} />
          {this.props.content}
        </Button>
      </Link>
    );
  }
}
