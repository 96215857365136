import './Registry.scss';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import AuthContext from 'context/AuthContext/authContext';
import {Button, Form} from 'react-bootstrap';
import {useEffectOnInit} from 'util/helperFunctions';
import DigicheckAuthAlert from 'components/alert/DigicheckAuthAlert';
import {useCallback, useContext, useEffect, useState} from 'react';


const Registry = ({history}) => {

  const [salutation, setSalutation] = useState('Frau');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);
  const [showPasswordConfirmCheck, setShowPasswordConfirmCheck] = useState(false);
  const [min8Lenth, setMin8Lenth] = useState(false);
  const [min1Upper, setMin1Upper] = useState(false);
  const [min1Lower, setMin1Lower] = useState(false);
  const [min1Number, setMin1Number] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [consentDeclarationAccepted, setConsentDeclarationAccepted] = useState(false);
  const [errorMessages, setErrorMessages] = useState(['']);
  const [showDigicheckAuthAlert, setShowDigicheckAuthAlert] = useState(false);

  const {authenticated, isLoading, register, showRegistrationFailedAlert} = useContext(AuthContext);

  useEffectOnInit(() => {
      if (authenticated) {
        const path: string | null = localStorage.getItem('lastPage');
        if (path) {
          history.push(path);
        } else {
          history.push('/profile');
        }
      }
    },
  );

  useEffect(() => {
    if (authenticated) {
      const path: string | null = localStorage.getItem('lastPage');
      if (path) {
        history.push(path);
      } else {
        history.push('/profile');
      }
    }
  }, [isLoading]);

  useEffect(() => {
    checkPassword();
    checkPasswordConfirm();
  }, [password, passwordConfirm]);

  useEffect(() => {
    let errors: string[] = [];
    if (showRegistrationFailedAlert) {
      errors.push("Die angegebene E-Mail-Adresse wird bereits verwendet.")
      setShowDigicheckAuthAlert(true);
    } else {
      setShowDigicheckAuthAlert(false);
    }
    setErrorMessages(errors);
  }, [showRegistrationFailedAlert]);

  const checkPassword = () => {
    const min8LenthElement = document.querySelector('#min8LengthElement');
    const min1UpperElement = document.querySelector('#min1UpperElement');
    const min1LowerElement = document.querySelector('#min1LowerElement');
    const min1NumberElement = document.querySelector('#min1NumberElement');

    if (min8LenthElement && min1UpperElement && min1NumberElement && min1LowerElement) {
      if (password.length >= 8) {
        setMin8Lenth(true);
        min8LenthElement.classList.add('passed');
      } else {
        setMin8Lenth(false);
        min8LenthElement.classList.remove('passed');
      }
      if (/[A-Z]/.test(password)) {
        setMin1Upper(true);
        min1UpperElement.classList.add('passed');
      } else {
        setMin1Upper(false);
        min1UpperElement.classList.remove('passed');
      }
      if (/[a-z]/.test(password)) {
        setMin1Lower(true);
        min1LowerElement.classList.add('passed');
      } else {
        setMin1Lower(false);
        min1LowerElement.classList.remove('passed');
      }
      if (/[0-9]/.test(password)) {
        setMin1Number(true);
        min1NumberElement.classList.add('passed');
      } else {
        setMin1Number(false);
        min1NumberElement.classList.remove('passed');
      }
    }
    setShowPasswordCheck(password !== '');
  };

  const checkPasswordConfirm = () => {
    const passwordsMatch = document.querySelector('#passwordsMatch');
    if (passwordsMatch) {
      if (passwordConfirm === password) {
        passwordsMatch.classList.add('passed');
      } else {
        passwordsMatch.classList.remove('passed');
      }
    }

    setShowPasswordConfirmCheck(passwordConfirm !== '');
  };

  const isValidRegistration = (): boolean => {
    let validRegistration = true;
    let errors: string[] = [];
    if (firstName === '') {
      validRegistration = false;
      errors = ['Bitte geben Sie einen Vornamen ein.'];
    }
    if (lastName === '') {
      validRegistration = false;
      errors = [...errors, 'Bitte geben Sie einen Nachnamen ein.'];
    }
    if (email === '') {
      validRegistration = false;
      errors = [...errors, 'Bitte geben Sie eine E-Mail-Adresse ein.'];
    }
    const mail = /.+@.+\.[A-Za-z]+$/;
    if (email !== '' && !email.match(mail)) {
      validRegistration = false;
      errors = [...errors, 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'];
    }
    if (!(min1Number && min1Lower && min1Upper && min8Lenth)) {
      validRegistration = false;
      errors = [...errors, 'Bitte geben Sie ein gültiges Passwort ein.'];
    }
    if (password !== passwordConfirm) {
      validRegistration = false;
      errors = [...errors, 'Die Passwörter stimmen nicht überein.'];
    }
    if (phoneNumber === '') {
      validRegistration = false;
      errors = [...errors, 'Bitte geben Sie eine Telefonnummer ein.'];
    }
    const re = /([+]?[0-9]([-]*[\\/]*[ ]*)*){6,}$/;
    if (phoneNumber !== '' && !phoneNumber.match(re)) {
      validRegistration = false;
      errors = [...errors, 'Bitte geben Sie eine gültige Telefonnummer ein.'];
    }
    if (!privacyAccepted) {
      validRegistration = false;
      errors = [...errors, 'Bitte akzeptieren Sie die Nutzungsbedingungen.'];
    }
    if (!consentDeclarationAccepted) {
      validRegistration = false;
      errors = [...errors, 'Bitte akzeptieren Sie die Datenverarbeitung.'];
    }
    setErrorMessages(errors);
    return validRegistration;
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (isValidRegistration()) {
      setShowDigicheckAuthAlert(false);
      const attributes: IAttributes = {
        phoneNumber,
        regDate: (new Date()).toISOString(),
        salutation,
        privacyAccepted,
        consentDeclarationAccepted,
      };
      const user: IUserRepresentation = {
        attributes,
        credentials: [{
          type: 'password',
          value: password,
          temporary: false,
        }],
        email,
        emailVerified: false,
        enabled: true,
        firstName,
        lastName,
      };
      register(user, history);
    } else {
      setShowDigicheckAuthAlert(true);
    }
  };

  return (
    <div className={'registry-form'}>
      <div className={'registry-title'}>
        <h1 className={'welcome'}>Anmelden</h1>
        <h4>Melden Sie sich hier an oder <a href={'/login'}>loggen Sie sich ein</a>, um die Ergebnisse Ihres
          Digitalisierungs-Checks als PDF-Dokument
          herunterzuladen und online in Ihrem Profil zu speichern.</h4>
        <h4>Die Ergebnisse Ihres Digitalisierungs-Checks bilden zudem die Grundlage für die kostenlose
          Digitalisierungsberatung
          durch unsere Spezialist*Innen.</h4>
      </div>
      {showDigicheckAuthAlert &&
      <DigicheckAuthAlert messages={errorMessages} success={false}/>
      }
      <Form className='keyfacts-form'>
        <Form.Group controlId='profileForm.salutation'>
          <Form.Label>Anrede:</Form.Label>
          <Form.Control as='select' value={salutation} onChange={event => setSalutation(event.target.value)}>
            <option value='Frau'>Frau</option>
            <option value='Herr'>Herr</option>
            <option value='Divers'>Divers</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='profileForm.firstName'>
          <Form.Label>Vorname:</Form.Label>
          <Form.Control type='text' value={firstName} onChange={event => setFirstName(event.target.value)}/>
        </Form.Group>
        <Form.Group controlId='profileForm.lastName'>
          <Form.Label>Nachname:</Form.Label>
          <Form.Control type='text' value={lastName} onChange={event => setLastName(event.target.value)}/>
        </Form.Group>
        <Form.Group controlId='profileForm.email'>
          <Form.Label>E-Mail-Adresse:</Form.Label>
          <Form.Control type='email' value={email} onChange={event => setEmail(event.target.value)}/>
        </Form.Group>
        <Form.Group controlId='profileForm.password'>
          <Form.Label>Passwort:</Form.Label>
          <Form.Control type='password' value={password} onChange={event => setPassword(event.target.value)}/>
        </Form.Group>
        <StylePasswordChecker show={showPasswordCheck}>
          <ul className='password-check'>
            <li id='min8LengthElement'>Mindestens 8 Zeichen</li>
            <li id='min1UpperElement'>Mindestens 1 Großbuchstabe</li>
            <li id='min1LowerElement'>Mindestens 1 Kleinbuchstabe</li>
            <li id='min1NumberElement'>Mindestens 1 Zahl</li>
          </ul>
        </StylePasswordChecker>
        <Form.Group controlId='profileForm.passwordConfirm'>
          <Form.Label>Passwort bestätigen:</Form.Label>
          <Form.Control type='password' value={passwordConfirm} onChange={event => setPasswordConfirm(event.target.value)}/>
        </Form.Group>
        <StylePasswordConfirmChecker show={showPasswordConfirmCheck}>
          <ul className='password-check'>
            <li id='passwordsMatch'>Passwörter stimmen überein</li>
          </ul>
        </StylePasswordConfirmChecker>
        <Form.Group controlId='profileForm.phoneNumber'>
          <Form.Label>Telefonnummer:</Form.Label>
          <Form.Control type='input' value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)}
                        pattern='([+]?[0-9]([-]*[\/]*[ ]*)*){6,}$' placeholder='z.B. 030 869 866 68'/>
        </Form.Group>
        <div className='checkboxen'>
          <Form.Group controlId={'profileForm.privacy'}>
            <Form.Check type='checkbox'>
              <Form.Check custom type='checkbox' checked={privacyAccepted} onChange={event => setPrivacyAccepted(event.target.checked)}
                          label={<div>Hiermit akzeptiere ich die <a href='/terms-of-use'> Nutzungsbedingungen </a>
                            und habe die <a href='/privacy'>Datenschutzhinweise</a> zur Kenntnis genommen.</div>}/>
            </Form.Check>
          </Form.Group>
          <Form.Group controlId={'profileForm.consentDeclaration'}>
            <Form.Check type='checkbox'>
              <div>
                <Form.Check custom type='checkbox' checked={consentDeclarationAccepted}
                            onChange={event => setConsentDeclarationAccepted(event.target.checked)}
                            label={<div>Mit dem Klick auf "Anmelden" erklären Sie sich einverstanden, dass Ihre Daten an die Berliner Sparkasse
                              übermittelt werden und Sie von dieser im Rahmen des Digitalisierungs-Checks Angebote und Beratungen telefonisch
                              oder über E-Mail erhalten. Ihre Einwilligung kann jederzeit unter der E-Mail-Address
                              <a href='mailto:datenschutz@s-digicheck.de'> datenschutz@s-digicheck.de</a> widerrufen werden.
                            </div>}/>
              </div>
            </Form.Check>
          </Form.Group>
        </div>
        <Button type="submit" value='send' variant='danger' onClick={handleRegistration}>
          Anmelden und Ergebnisse anzeigen
        </Button>
        <div>
          <Link to='/login' className='login-link'>Zurück zum Login</Link>
        </div>
      </Form>
    </div>
  );
};

const StylePasswordChecker = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
`;

const StylePasswordConfirmChecker = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
`;

export default Registry;
