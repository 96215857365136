import close from 'assets/icons/close@3x.png';
import AuthContext from 'context/AuthContext/authContext';
import {useContext} from 'react';
import {Button, Modal} from 'react-bootstrap';
import './ResetQuestionnaireModal.scss';


const ResetQuestionnaireModal = ({showModal, setShowModal, history}) => {

  const {authenticated} = useContext(AuthContext);

  const hideModal = () => {
    setShowModal(false);
  };

  const handleResetQuestionnaire = () => {
    localStorage.removeItem('digiCheckState');
    if (!authenticated) {
      history.push('/start');
    } else {
      history.push('/profile');
    }
  };

  return(
    <div>
      {showModal ?
        <Modal className='reset-questionnaire-modal' show={showModal} onHide={hideModal} animation={false} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
          <Modal.Header translate={'yes'}>
            <Modal.Title className='info-modal-title'>Digitalisierungs-Check beenden?</Modal.Title>
            <img src={close} alt='' onClick={hideModal}></img>
          </Modal.Header>
          <Modal.Body>
            <div className='pb-3'>
              <p>Sollten Sie nicht eingeloggt sein, gehen alle Ihre bisher eingegebenen Daten verloren.</p>
            </div>
            <Button variant={'light'} onClick={handleResetQuestionnaire}>Ja, beenden</Button>
            <Button variant={'danger'} onClick={hideModal}>Nein, nicht beenden</Button>
          </Modal.Body>
        </Modal>
        : null}
    </div>
  );
};

export default ResetQuestionnaireModal;
