import { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';

export default class Imprint extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='static-page-wrapper'>
        <h1>Impressum</h1>

        <Jumbotron>
          <h2>Diensteanbieter</h2>
          <p> Die Web­sei­te <a href='https://s-digicheck.de'>s-digicheck.de</a> wird in Kooperation mit der Berliner Sparkasse be­trie­ben durch: </p>
          <p>
            EXXETA AG<br />
           Victor-Gollancz-Straße 5<br />
            D-76137  Karls­ru­he
          </p>
          <p>
            tel +49 721 50994-5000<br />
            fax +49 721 50994-5299<br />
            mail: <a href='mailto:info@EXXETA.com'>info@EXXETA.com</a>
          </p>
          <p>
            HRB 702566<br />
            Amts­ge­richt Mann­heim<br />
            USt-IdNr. DE813026499
          </p>
          <p>
            Vertretungsberechtigter Vorstand<br />
            Dr. Pe­ter Hei­ne<br />
            Achim Kirch­gäs­s­ner<br />
            An­dre­as Rit­ter<br />
          </p>
          <p>
            Aufsichtsratsvorsitzender<br />
            Ul­rich A. Götz
          </p>
          <p>
            Inhaltlich ver­ant­wort­li­ch:<br />
            Oli­ver Pitz­schel, Victor-Gollancz-Straße 5, 76137 Karls­ru­he
          </p>
          <p>
            Der Nut­zung von im Rah­men der Im­pres­s­ums­pflicht ver­öf­fent­lich­ten Kon­takt­da­ten durch Drit­te zur Über­sen­dung von nicht aus­drück­lich an­ge­for­der­ter Wer­bung und In­for­ma­ti­ons­ma­te­ria­li­en wird hier­mit aus­drück­lich wi­der­spro­chen. Die Be­trei­ber der Sei­ten be­hal­ten sich aus­drück­lich recht­li­che Schrit­te im Fal­le der un­ver­lang­ten Zu­sen­dung von Wer­bein­for­ma­tio­nen, etwa durch Spam-Mails, vor.
          </p>
        </Jumbotron>
      </div>
    );
  }
}
