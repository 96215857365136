import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import ConsultantLinkGenerator from 'components/common/consultant-link-generator/ConsultantLinkGenerator';

import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <footer className='mt-auto'>
      <Container className='container'>
        <Row>
          <Col className='footer-link-margin' sm={12} md={6} lg='auto'>
            <Link to='/privacy'>Datenschutz</Link>
          </Col>
          <Col className='footer-link-margin' sm={12} md={6} lg='auto'>
            <Link to='/imprint'>Impressum</Link>
          </Col>
          <Col className='footer-link-margin' sm={12} md={6} lg='auto'>
            <Link to='/terms-of-use'>Nutzungsbedingungen</Link>
          </Col>
          <Col className='footer-link-margin' sm={12} md={6} lg='auto'>
            <ConsultantLinkGenerator />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
