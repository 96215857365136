import './ResultChart.scss';
import { Component } from 'react';
import RadialSeparators from './RadialSeparators';
import 'react-circular-progressbar/dist/styles.css';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';

export class ResultChart extends Component<any> {

  private maxScore: number = 4.0;
  private score: number = Number((this.props.score / 100).toFixed(1));
  private scoreAverage: number = Number((this.props.scoreAverage / 100).toFixed(1));
  private scoreValue: string = this.score.toLocaleString(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  private maxScoreValue: string = this.maxScore.toLocaleString(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 });

  render() {
    return (
      <div className='pie-chart'>
        <CircularProgressbarWithChildren 
        maxValue={4} 
        strokeWidth={10}
        value={this.score} 
        text={this.scoreValue} 
        styles={buildStyles({
          textSize: '16px',
          strokeLinecap: 'butt',
          pathColor: '#FF0500',
          textColor: '#444444',
          pathTransitionDuration: 1.5,
        })}>
          <RadialSeparators count={4}
            style={{
              width: '5px',
              height: `${23}%`,
              background: '#fff',
            }}
          />
          <div style={{ width: "76%" }}>

          <CircularProgressbar
            maxValue={4} 
            strokeWidth={4} 
            value={this.scoreAverage} 
            className={'CircularProgressbarClass'} 
            styles={buildStyles({
              pathColor: 'black',
              strokeLinecap: "butt",
              trailColor: 'transparent',
            })}
          />
          </div>
        </CircularProgressbarWithChildren>

        <div className='pie-chart-score'>von {this.maxScoreValue}</div>
      </div>
    );
  }
}

export default ResultChart;
