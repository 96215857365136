import './TopSolutionPartnerItem.scss';
import ImageTag from '../image-tag/ImageTag';
import { Component } from 'react';
import { Card, Col, Image } from 'react-bootstrap';
import { generateLinkOutURL } from 'util/LinkOutURLGenerator';

export default class TopSolutionPartnerItem extends Component<ISolutionPartnerItemProps> {

  render() {
    return (
      <Col md={4} className='result-top-solution-partner-item-wrapper'>
        <Card className='pb-4 pb-md-0'>
          <div className='image-wrapper d-flex align-items-center'>
            <a href={generateLinkOutURL(this.props.solutionPartner.url, this.props.solutionPartner.name)} className='top-solution-link'
            onClick={() => this.props.onPartnerClick(this.props.solutionPartner)} target='_blank' rel='noopener noreferrer'>
              <Image src={`/images/${this.props.solutionPartner.imageName}`} />
            </a>
          </div>

          <h3 className="topic-name">{this.props.solutionPartner.topic.name}</h3>

          <ImageTag solutionPartner={this.props.solutionPartner} onFavoriteChange={this.props.onFavoriteChange} />
          <h3>
          <a href={generateLinkOutURL(this.props.solutionPartner.url, this.props.solutionPartner.name)} className='top-solution-link top-solution-partner-title'
          onClick={() => this.props.onPartnerClick(this.props.solutionPartner)} target='_blank' rel='noopener noreferrer'>
              { this.props.solutionPartner.name }
          </a>
          </h3>
          <p className='description'>{this.props.solutionPartner.description}</p>

          <div className='favourite-button-position'>
            <a href={generateLinkOutURL(this.props.solutionPartner.url, this.props.solutionPartner.name)} className='top-solution-link'
            onClick={() => this.props.onPartnerClick(this.props.solutionPartner)} target='_blank' rel='noopener noreferrer'>
                Zum Anbieter
            </a>
          </div>
        </Card>
      </Col>
    );
  }
}
