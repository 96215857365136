import {Component} from 'react';
import {Jumbotron} from 'react-bootstrap';
import {Analytics} from 'util/Analytics';


export default class Privacy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='static-page-wrapper'>
                <h1>Datenschutzerklärung</h1>

                <Jumbotron>
                    <h2>Datenschutzhinweise für den Digitalisierungscheck</h2>

                    <p className='subtitle'>Allgemeine Hinweise</p>
                    <p>
                        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
                        Daten passiert, wenn Sie unsere Website
                        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden
                        können. Ausführliche Informationen zum
                        Thema Datenschutz entnehmen Sie unseren unter diesem Text aufgeführten Datenschutzhinweisen.
                    </p>

                    <p>Der Verantwortliche im Sinne der EU Datenschutz-Grundverordnung ist die</p>
                    <p>
                        EXXETA AG<br/>
                        Victor-Gollancz-Straße 5<br/>
                        D-76137 Karlsruhe
                    </p>
                    <p>
                        tel +49 721 50994-5000<br/>
                        fax +49 721 50994-5299<br/>
                        e-mail: <a href='mailto:datenschutz@EXXETA.com'>datenschutz@EXXETA.com</a>
                    </p>

                    <p>
                        Vertretungsberechtigter Vorstand: Dr. Peter Heine, Achim Kirchgässner, Andreas Ritter<br/>
                        Aufsichtsratsvorsitzender: Ulrich A. Götz<br/>
                    </p>
                    <p>
                        Sie erreichen unseren Datenschutzbeauftragten unter:
                    </p>
                    <p>
                        secopan GmbH<br/>
                        Am Schönblick 14<br/>
                        71229 Leonberg<br/>
                    </p>
                    <p>
                        e-mail: <a href='mailto:datenschutz@secopan.de'>datenschutz@secopan.de</a>
                    </p>

                    <p className='subtitle'>Empfänger der Daten</p>
                    <p>
                        Landesbank Berlin/Berliner Sparkasse
                    </p>

                    <p className='subtitle'>Wie erfassen wir Ihre Daten?</p>
                    <p>
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich
                        z. B. um Daten handeln, die Sie in ein
                        Kontaktformular eingeben.
                    </p>
                    <p>
                        Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website registrieren. Bei der
                        Registrierung abgefragte Pflichtangaben sind
                        vollständig anzugeben. Andernfalls werden wir die Registrierung ablehnen. Die Verarbeitung der
                        eingegebenen Daten erfolgt auf Grundlage
                        Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten
                        Einwilligung ist jederzeit möglich. Für den Widerruf
                        genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten
                        Datenverarbeitung bleibt vom Widerruf unberührt.
                    </p>
                    <p>
                        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                        sind vor allem technische Daten (z. B.
                        Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
                        erfolgt automatisch, sobald Sie unsere Website
                        betreten.
                    </p>

                    <p className='subtitle'>Google Analytics</p>
                    <p>
                        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google
                        Ireland Limited („Google“), Gordon House,
                        Barrow Street, Dublin 4, Irland.
                    </p>
                    <p>
                        Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu
                        analysieren. Hierbei erhält der Websitebetreiber
                        verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
                        Herkunft des Nutzers. Diese Daten werden
                        von Google ggf. in einem Profil zusammengefasst, das dem jeweiligen Nutzer bzw. dessen Endgerät
                        zugeordnet ist.<br/>
                        Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der
                        Analyse des Nutzerverhaltens ermöglichen
                        (z.B. Cookies oder Device-Fingerprinting). Die von Google erfassten Informationen über die
                        Benutzung dieser Website werden in der Regel
                        an einen Server von Google in den USA übertragen und dort gespeichert.
                    </p>
                    <p>
                        Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                        Websitebetreiber hat ein berechtigtes Interesse
                        an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
                        optimieren. Sofern eine entsprechende Einwilligung
                        abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung
                        ausschließlich auf Grundlage von
                        Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
                    </p>
                    <p>
                        Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre
                        IP-Adresse von Google innerhalb von Mitgliedstaaten
                        der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
                        Wirtschaftsraum vor der Übermittlung in die USA
                        gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
                        übertragen und dort gekürzt. Im Auftrag des
                        Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                        auszuwerten, um Reports über die Websiteaktivitäten
                        zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
                        Dienstleistungen gegenüber dem Websitebetreiber zu
                        erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
                        nicht mit anderen Daten von Google zusammengeführt.<br/>
                        Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die
                        strengen Vorgaben der deutschen Datenschutzbehörden
                        bei der Nutzung von Google Analytics vollständig um.
                    </p>
                    <p>
                        Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das
                        unter dem folgenden Link verfügbare Browser-Plugin
                        herunterladen und installieren: <a
                        href='https://tools.google.com/dlpage/gaoptout?hl=de'>https://tools.google.com/dlpage/gaoptout?hl=de</a>.
                        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
                        Datenschutzerklärung von Google: <a
                        href='https://support.google.com/analytics/answer/6004245?hl=de'>
                        https://support.google.com/analytics/answer/6004245?hl=de</a>.
                    </p>

                    <p className='subtitle'>Google Optimize</p>
                    <p>
                        Unsere Webseite nutzt das mit Google Analytics verbundene Webanalysetool Google Optimize. Der
                        Anbieter ist Google Inc. ("Google"), 600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Für
                        den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
                        Dublin 4, Irland) für alle Google-Dienste verantwortlich.
                    </p>
                    <p>
                        Bei der Verwendung von Google Optimize werden Cookies (Textdateien) eingesetzt. Diese Cookies
                        werden auf Ihrem Computer gespeichert und ermöglichen uns eine Analyse Ihrer Benutzung unserer
                        Webseite. Die hiefür erzeugten Informationen über Ihre Benutzung unserer Webseite werden an
                        einen Server von Google in den USA übertragen und dort gespeichert.
                    </p>
                    <p>
                        Die Speicherung von Google-Optimize Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
                        DSGVO.
                        Wir als Webseitenbetreiber haben ein berechtigtes Interesse an der Analyse des Nutzerverhaltens,
                        um
                        sowohl unser Webangebot als auch unsere Werbung zu optimieren.
                    </p>
                    <p>
                        Mehr über die Daten, die durch die Verwendung von Google Optimize verarbeitet werden, erfahren
                        Sie
                        in der Privacy Policy auf <a
                        href='https://policies.google.com/privacy?hl=de'>https://policies.google.com/privacy?hl=de</a>.
                    </p>

                    <p className='subtitle'>Cookie-Einwilligung mit consentmanager</p>
                    <p>
                        Diese Website nutzt die Cookie-Consent-Technologie von consentmanager, um Ihre Einwilligung zur
                        Speicherung bestimmter Cookies auf
                        Ihrem Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform
                        zu dokumentieren. Anbieter dieser Technologie
                        ist die consentmanager AB, Håltegelvägen 1b, 72348 Västerås, Website: <a
                        href='https://www.consentmanager.de'>https://www.consentmanager.de
                    </a> (im Folgenden „consentmanager“).<br/>
                        Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten an consentmanager
                        übertragen:
                        <ul>
                            <li>
                                Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)
                            </li>
                            <li>
                                Ihre IP-Adresse
                            </li>
                            <li>
                                Informationen über Ihren Browser
                            </li>
                            <li>
                                Informationen über Ihr Endgerät
                            </li>
                            <li>
                                Zeitpunkt Ihres Besuchs auf der Website
                            </li>
                        </ul>
                        Des Weiteren speichert consentmanager ein Cookie in Ihrem Browser, um Ihnen die erteilten
                        Einwilligungen bzw. deren Widerruf zuordnen
                        zu können. Die so erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, das
                        consentmanager-Cookie selbst löschen oder
                        der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungspflichten
                        bleiben unberührt.<br/>
                        Der Einsatz von consentmanager erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für
                        den Einsatz bestimmter Technologien einzuholen.
                        Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. c DSGVO.<br/>
                        Hier können Sie jederzeit Ihre Einwilligung zu Cookies prüfen und anpassen: <span
                        onClick={Analytics.getInstance().showConsentScreenAdvanced}>zur Cookie-Einwilligungs-Lösung</span>.
                    </p>

                    <p className='subtitle'>Wofür nutzen wir Ihre Daten?</p>
                    <p>
                        Wir verwenden in Übereinstimmung mit dem geltenden Recht Ihre Informationen unter anderem, um
                        Ihre Nutzung von Angeboten oder Diensten, die
                        Sie erhalten möchten, zu ermöglichen, zu verwalten und zu managen und statistische Analysen
                        durchzuführen, wobei in diesen Fällen auf
                        aggregierte Daten zurückgegriffen wird. Zudem werden die Daten entsprechend ihrer Einwilligung
                        an die Landesbank Berlin/Berliner Sparkasse
                        (im Folgenden Kreditinstitut) übermittelt. Ein Teil der Daten wird erhoben, um eine fehlerfreie
                        Bereitstellung der Website zu gewährleisten.
                        Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                    </p>

                    <p className='subtitle'>Übermittlung personenbezogener Daten</p>
                    <p>
                        Die EXXETA AG wird Ihre Daten nicht an Dritte weitergeben, es sei denn, Sie haben vorher Ihr
                        ausdrückliches Einverständnis zu einer Weitergabe
                        gegeben oder die Übermittlung ist gesetzlich vorgeschrieben bzw. gesetzlich zulässig.
                    </p>
                    <p>
                        Ausgenommen hiervon sind die Dienstleistungspartner der EXXETA AG, die zur Abwicklung des
                        Vertragsverhältnisses benötigt werden und von uns im
                        Rahmen eines Auftragsverarbeitungsvertrages mit der Verarbeitung personenbezogener Daten nach
                        unserer Weisung beauftragt wurden. Die EXXETA AG
                        arbeitet grundsätzlich nur mit Dienstleistungspartnern zusammen, die ein angemessenes Maß an
                        Datenschutz, Sicherheit und Vertraulichkeit
                        gewährleisten und die alle geltenden gesetzlichen Anforderungen für die Übertragung
                        personenbezogener Daten außerhalb des Landes, in dem sie
                        ursprünglich erfasst wurden, erfüllen.
                    </p>
                    <p>
                        Mit Registrierung haben Sie Ihr ausdrückliches Einverständnis zu einer Weitergabe bzw.
                        Übermittlung Ihrer Daten an das Kreditinstitut und zu
                        einer gewerblichen Ansprache durch dieses gegeben. Ein Widerruf Ihrer bereits erteilten
                        Einwilligung ist jederzeit möglich. Das Kreditinstitut
                        hat eine eigene Verantwortung und eigene Pflichten im Hinblick auf die Verarbeitung Ihrer
                        personenbezogenen Daten. Ab Übermittlung der Daten ist
                        der Widerspruch im Zusammenhang mit der Verarbeitung und gewerblichen Nutzung durch das
                        Kreditinstitut an dieses zu stellen. Sofern Sie Fragen zum
                        Umgang mit Ihren personenbezogenen Daten seitens des Kreditinstituts haben, sollten Sie sich
                        direkt mit dem Kreditinstitut in Verbindung setzen.
                        Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                    </p>

                    <p className='subtitle'>Websites Dritter</p>
                    <p>
                        Unsere Website kann Links zu den Websites Dritter enthalten. Denken Sie beim Zugriff auf solche
                        Websites Dritter daran, dass für jede dieser Websites
                        eine eigene Datenschutzerklärung gilt. Wenngleich EXXETA AG bei der Auswahl von Websites für
                        solche Links äußerste Vorsicht walten lässt, können wir
                        keine Verantwortung für den Umgang mit Ihren personenbezogenen Daten seitens der Betreiber
                        dieser Websites übernehmen.
                    </p>

                    <p className='subtitle'>Speicherdauer</p>
                    <p>
                        Soweit bei der Erhebung keine ausdrückliche Speicherdauer angegeben wird, werden die
                        personenbezogenen Daten gelöscht, soweit diese nicht mehr zur
                        Erfüllung des Zweckes der Speicherung erforderlich sind, es sei denn gesetzliche
                        Aufbewahrungspflichten (z.B. handels- und steuerrechtliche
                        Aufbewahrungspflichten) stehen einer Löschung entgegen.
                    </p>

                    <p className='subtitle'>Alle weiteren Informationen können Sie unserer allgemeinen
                        Datenschutzerklärung für unserer Webseite entnehmen. Diese finden Sie unter:</p>
                    <p>
                        <a href='https://www.exxeta.com/de/datenschutz/'>https://www.exxeta.com/de/datenschutz/</a>
                    </p>
                </Jumbotron>
            </div>
        );
    }
}
