import './ImageTag.scss';
import starIcon from 'assets/icons/star-white@3x.png';
import { Button } from 'react-bootstrap';
import starIconFull from 'assets/icons/star-full-white@3x.png';
import { Component } from 'react';

export default class ImageTag extends Component<ISolutionPartnerItemProps> {

  renderText() {
    if (this.props.solutionPartner.isFavorite) {
      return 'als Favorit gespeichert';
    }

    return 'als Favorit speichern';
  }

  getClassName() {
    if (this.props.solutionPartner.isFavorite) {
      return 'saved';
    }

    return 'unsaved';
  }

  render() {
    return (
      <div className='image-tag-wrapper' onClick={() => this.props.onFavoriteChange(this.props.solutionPartner)}>
        <Button variant='danger' className={ this.getClassName() }>
          <span className='d-print-none'>{ this.renderText() }</span>

          <img src={this.props.solutionPartner.isFavorite ? starIconFull : starIcon}
            alt={this.props.solutionPartner.isFavorite ? 'als Favorit gespeichert' : 'als Favorit speichern'} />
        </Button>
      </div>
    );
  }
}
