import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Component } from 'react';
import chevronForward from 'assets/icons/circle_chevron@3x.png';


export default class ButtonRed extends Component<IButtonProps> {
  render() {
    return (
      <Link to={this.props.url}>
        <Button variant='danger'>
          {this.props.content}
          <img className='chevron-forward' src={chevronForward} alt={this.props.content} />
        </Button>
      </Link>
    );
  }
}
