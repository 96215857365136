import './Result.scss';
import Score from './score/Score';
import {Link} from 'react-router-dom';
import Contact from 'components/common/contact/Contact';
import AuthContext from 'context/AuthContext/authContext';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import ProfileService from 'services/ProfileService';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import chevronBackward from 'assets/icons/chevron-circle-right@3x.png';
import SolutionPartner from './solution-partner/SolutionPartner';
import {useEffectOnInit} from 'util/helperFunctions';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import {Button, Col, Row} from 'react-bootstrap';
import QuestionnaireService from 'services/QuestionnaireService';
import {generateLinkOutURL} from 'util/LinkOutURLGenerator';
import {useContext, useState} from 'react';
import {getContactPrefilledUrl} from 'util/ContactUrlGenerator';
import {uncheckedTopicExisting} from '../QuestionnaireUtil';


export default function Result(props: IResultProps) {
  const questionnaireUuid = props.match.params.uuid;
  const [questionnaire, setQuestionnaire] = useState({} as IQuestionnaire);
  const [profile, setProfile] = useState({} as IProfile);
  const [consultantLink, setConsultantLink] = useState(getContactPrefilledUrl());
  const {setPrefilledContactUrl} = useContext(AuthContext);

  useEffectOnInit(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('lastPage', window.location.pathname);
    setPrefilledContactUrl();
    loadQuestionnaire();
    loadProfile();
  });

  const loadQuestionnaire = () => {
    QuestionnaireService.getQuestionnaireById(questionnaireUuid)
      .then(res => {
        const loadedQuestionnaire: IQuestionnaire = res.data;
        if (loadedQuestionnaire.completed) {
          setQuestionnaire(loadedQuestionnaire);
        } else if (uncheckedTopicExisting(loadedQuestionnaire)[0]) {
          props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + uncheckedTopicExisting(loadedQuestionnaire)[1]);
        } else {
          props.history.push('/questionnaire/' + questionnaireUuid + '/summary');
        }
      })
      .catch(err => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(err.message + ' Unauthorized user in GET /questionnaires/{uuid}');
        } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
          console.error(err.message + ' Questionnaire not found in GET /questionnaires/{uuid}');
        } else {
          console.error(err.message + ' in GET /questionnaires/{uuid}');
        }
      });
  };

  const loadProfile = () => {
    ProfileService.getOrCreateProfile()
      .then(res => {
        setProfile(res.data);
      })
      .catch(err => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(err.message + ' Unauthorized user in GET /users/profile/getOrCreate');
        } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
          console.warn(err.message + ' No profile found in GET /users/profile/getOrCreate');
        } else {
          console.error(err.message + ' in GET /users/profile/getOrCreate');
        }
      });
  };

  const handleBackButton = () => {
    const lastCategoryIndex = questionnaire.categories.length - 1;
    const lastCategory = questionnaire.categories[lastCategoryIndex];

    const lastTopicIndex = lastCategory.topics.length - 1;
    const lastTopic = lastCategory.topics[lastTopicIndex];

    props.history.push('/questionnaire/' + questionnaire.uuid + '/topics/' + lastTopic.uuid);
  };

  const onClick = () => {
    const generatedLink = generateLinkOutURL(getContactPrefilledUrl());
    setConsultantLink(generatedLink);
  };

  if (questionnaire !== undefined && Object.keys(questionnaire).length !== 0) {
    return (
      <div className='result-wrapper'>
        <Score profile={profile} questionnaire={questionnaire} showPrint={true} history={props.history} isProfilePage={false} />
        <Contact profile={profile}/>
        <SolutionPartner questionnaire={questionnaire} />
        <div className='result-arrange-appointment'>
          <a href={consultantLink} onClick={() => onClick()} className='button btn btn-danger bottom' target='_blank' rel='noopener noreferrer'>
            Jetzt Beratungstermin vereinbaren
            <img src={chevronForward} className='chevron-forward' alt='Jetzt Beratungstermin vereinbaren' />
          </a>
        </div>
        <Row className='result-back-to-profile'>
          <Col xs={6} md={6} className='pl-0'>
            <Button className='mr-3' variant='info' onClick={handleBackButton}>
              <img src={chevronBackward} className='chevron-backward' alt='Zum Fragebogen' />
              Zum Fragebogen
            </Button>
          </Col>
          <Col xs={6} md={6} className='pl-0 text-right'>
            <Link to='/profile'>
              <Button variant='light'>
                <img src={chevronBackward} className='chevron-backward' alt='Zurück zum Profil' />
                Zurück zum Profil
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <LoadingSpinner text='Lade Fragebogen' />
  );
}
