import App from 'App';
import axios from 'axios';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {Analytics} from 'util/Analytics';
import {digiHistory} from 'util/DigiHistory';
import { createRoot } from 'react-dom/client';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import * as serviceWorker from 'serviceWorker';
import AuthContextProvider from 'context/AuthContext/authContextProvider';


Analytics.getInstance().initEventListeners();

axios.defaults.headers.common['Content-Type'] = 'application/json';

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <AuthContextProvider>
    <App /> 
  </AuthContextProvider>
);

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error !== undefined && error.response !== undefined) {
    if (error.response.status === EHttpStatusCode.NOT_FOUND) {
      if (!(error.config.url === '/api/v1/users/profile' && error.config.method === 'get')) {
        digiHistory.push('/404');
      }
    } else if (error.response.status === EHttpStatusCode.INTERNAL_SERVER_ERROR) {
      digiHistory.push('/500');
    }
  }

  return Promise.reject(error);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
