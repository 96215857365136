import {Component} from 'react';
import {Analytics} from 'util/Analytics';
import {generateLinkOutURL} from 'util/LinkOutURLGenerator';
import {getContactPrefilledUrl} from 'util/ContactUrlGenerator';

import './ConsultantLinkGenerator.scss';

export default class ConsultantLinkGenerator extends Component<{}, IConsultantLinkGeneratorState> {

  constructor(props: any) {
    super(props);
    this.state = {
      consultantLink: getContactPrefilledUrl(),
    };
  }

  onClick() {
    Analytics.getInstance().onDigitizationAdviceButtonClicked();
    const generatedLink = generateLinkOutURL(getContactPrefilledUrl());
    this.setState({
      consultantLink : generatedLink,
    });
  }

  render() {
    return (
      <a href={this.state.consultantLink} onClick={() => this.onClick()} className='consultant-link' target='_blank' rel='noopener noreferrer'>
        Zur Digitalisierungsberatung
      </a>
    );
  }
}
