import {Component} from 'react';
import {Jumbotron} from 'react-bootstrap';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import QuestionnaireService from 'services/QuestionnaireService';
import TopSolutionPartnerList from './top-solution-partner/TopSolutionPartnerList';
import SolutionPartnerService from 'services/SolutionPartnerService';
import FurtherSolutionPartnerList from './further-solution-partner/FurtherSolutionPartnerList';


export default class SolutionPartner extends Component<ISolutionPartnerProps, ISolutionPartnerState> {

  constructor(props: ISolutionPartnerProps) {
    super(props);

    this.state = {
      userSolutionPartnerFavorites: new Array<ISolutionPartner>(),
      questionnaire: this.props.questionnaire,
    };
  }

componentDidMount() {
  this.loadUserSolutionPartnerFavorites();
}


loadUserSolutionPartnerFavorites() {
  SolutionPartnerService.getFavorites()
    .then(res => {
      this.setState({ userSolutionPartnerFavorites: res.data }, this.setUserFavorites);
    })
    .catch(err => {
      if (err.response.status === EHttpStatusCode.FORBIDDEN) {
        console.error(err.message + ' Unauthorized user in GET  /favorites/solution-partners');
      } else {
        console.error(err.message + ' in GET /favorites/solution-partners');
      }
    });
}

 setUserFavorites() {
  const { questionnaire, userSolutionPartnerFavorites } = this.state;
  if (questionnaire.solutionPartnerRecommendations !== undefined) {
    questionnaire.solutionPartnerRecommendations.forEach(solutionPartner => {
      if (userSolutionPartnerFavorites.map(fav => fav.uuid).includes(solutionPartner.uuid)) {
        solutionPartner.isFavorite = true;
      } else {
        solutionPartner.isFavorite = false;
      }
    });
  }

  this.setState({ questionnaire });
}

getNumberOfTopSolutionPartners() {
  return 3;
}

handleFavorite(solutionPartner: ISolutionPartner) {
  if (solutionPartner.isFavorite) {
    SolutionPartnerService.deleteFavorite(solutionPartner.uuid)
      .then(res => {
        this.loadUserSolutionPartnerFavorites();
        this.setUserFavorites();
      })
      .catch(err => {
        console.error(err.message + ' in DELETE /favorites/solution-partners/' + solutionPartner.uuid);
      });
  } else {
    SolutionPartnerService.postFavorite(solutionPartner.uuid, this.state.questionnaire.uuid)
      .then(res => {
        this.loadUserSolutionPartnerFavorites();
        this.setUserFavorites();
      })
      .catch(err => {
        console.error(err.message + ' in POST /favorites/solution-partners/' + solutionPartner.uuid);
      });
  }
}
onPartnerClick(solutionPartner: ISolutionPartner) {
  const { questionnaire } = this.state;

  QuestionnaireService.putSolutionPartnerClickCount(questionnaire.uuid, solutionPartner.uuid)
      .catch(err => {
        console.error(err.message +  ' in PUT /questionnaires/' + questionnaire.uuid + '/solution_partners/' + solutionPartner.uuid);
      });
}

render() {
  return (
    <Jumbotron className='result-solution-partner-wrapper'>
      <TopSolutionPartnerList questionnaire={this.state.questionnaire} onFavoriteChange={this.handleFavorite.bind(this)}
        numberTopSolutionPartners={this.getNumberOfTopSolutionPartners()} onPartnerClick={this.onPartnerClick.bind(this)} />
      <FurtherSolutionPartnerList questionnaire={this.state.questionnaire} onFavoriteChange={this.handleFavorite.bind(this)}
        numberTopSolutionPartners={this.getNumberOfTopSolutionPartners()} onPartnerClick={this.onPartnerClick.bind(this)} />
    </Jumbotron>
  );
}
}