import axios, {AxiosResponse} from 'axios';

class IndustryService {

  getIndustries = async (): Promise<AxiosResponse> => {
    return axios.get(process.env.REACT_APP_API_BASE + '/industries', {withCredentials: false});
  }

  getIndustryCategories = async (industryId: string): Promise<AxiosResponse> => {
    return axios
      .get(process.env.REACT_APP_API_BASE + '/industries/' + industryId + '/categories', {withCredentials: false});
  }

}
export default new IndustryService();
