import './Keyfacts.scss';
import pieChart from 'assets/icons/chart-pie-alt@3x.png';
import gem from 'assets/icons/gem@3x.png';
import Tile from 'components/start/tile/Tile';
import users from 'assets/icons/users-grey@3x.png';
import userTie from 'assets/icons/user-tie@3x.png';
import clipboard from 'assets/icons/clipboard-list-check@3x.png';
import {Analytics} from 'util/Analytics';
import ProfileInfo from 'components/profile/profile-info/ProfileInfo';
import welcomeImage from 'assets/images/landing@3x.png';
import ContentHeader from 'components/common/content-header/ContentHeader';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import {useEffectOnInit} from 'util/helperFunctions';
import {Button, CardDeck, Col, Row} from 'react-bootstrap';
import {useEffect, useRef, useState} from 'react';


export default function Keyfacts(props: IKeyfactsProps) {
    const child = useRef<any>();
    const [isIndustryDefault, setIsIndustryDefault] = useState(true);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    useEffectOnInit(() => {
        localStorage.setItem('lastPage', window.location.pathname);
    });

    useEffect(() => {
        if (child.current.profile.industry && child.current.profile.industry.name !== '') {
            setIsIndustryDefault(false);
            Analytics.getInstance().onDigiCheckStarted();
        } else {
            setIsIndustryDefault(true);
        }

    }, [isIndustryDefault]);

    const postUser = () => {
        setIsButtonClicked(true);
        child.current.postUser(false);
    };

    const resetButtonToEnabled = () => {
        setIsButtonClicked(false);
    };

    return (
        <div>
            <h5 className='question-heading'>
                Bitte geben Sie die Kerninformationen zu Ihrem Unternehmen an,<br>
            </br>damit der Digitalisierungs-Check auf Sie abgestimmt wird.
            </h5>
            <ProfileInfo
                ref={child}
                history={props.history}
                match={props.match}
                resetButtonToEnabled={resetButtonToEnabled}
                setIsIndustryDefault={setIsIndustryDefault}
            />

            <Row>
                <Col md={{order: 1, span: 6}} xs={{order: 2, span: 12}} className='mt-5 mt-sm-0'>
                </Col>
                <Col md={{order: 2, span: 6}} xs={{order: 1, span: 12}} className='text-md-right oposite-side-buttons'>
                    <Button
                        variant='danger'
                        className='default-button'
                        disabled={isIndustryDefault || isButtonClicked} onClick={() => postUser()}
                    >
                        Weiter
                        <img src={chevronForward} className='chevron-forward' alt='Weiter'/>
                    </Button>
                </Col>
            </Row>
        </div>
    );

}
