import {Link} from 'react-router-dom';
import AuthContext from 'context/AuthContext/authContext';
import {Button, Form} from 'react-bootstrap';
import Sparkassenlogo from 'assets/images/logo_m_wht@3x.png';
import DigicheckAuthAlert from 'components/alert/DigicheckAuthAlert';
import {useContext, useState} from 'react';
import './Login.scss';


const Login = ({history}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {login, showLoginFailedAlert} = useContext(AuthContext);

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(username, password, history);
  };

    return (
      <div>
      <div className={'login-form'}>
        <div className={'sparkassen-logo'}><img src={Sparkassenlogo} alt='Sparkasse'></img></div>
        <h1 className={'welcome'}>Digitalisierungs-Check</h1>
        { showLoginFailedAlert &&
        <DigicheckAuthAlert messages={['Ungültige E-Mail-Adresse oder Passwort.']} success={false}/>
        }
        <Form>
          <Form.Group controlId='profileForm.email'>
            <Form.Label>E-Mail-Adresse:</Form.Label>
            <Form.Control type='text' value={username} autoFocus onChange={handleUsernameChange}/>
          </Form.Group>
          <Form.Group controlId='profileForm.password'>
            <Form.Label>Passwort:</Form.Label>
            <Form.Control type='password' value={password} onChange={handlePasswordChange}/>
          </Form.Group>
          <div className={'reset-password'}>
            <Link to={'/resetPassword'}>Passwort vergessen?</Link>
          </div>
          <Button type="submit" variant='danger' onClick={handleLogin}>
            Login
          </Button>
          <div className={'register'}>
            Sie haben noch kein Konto? <Link to='/registry'>Melden Sie sich hier an.</Link>
          </div>
        </Form>
      </div>
      </div>
    );
};

export default Login;
