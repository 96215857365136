import axios, {AxiosRequestConfig} from 'axios';
import qs from 'qs';

export class KeyCloakService {

  private configOptions: AxiosRequestConfig = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, withCredentials: false};

  getAdminAccess(){
    return axios.post(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/realms/master/protocol/openid-connect/token`, qs.stringify({
      grant_type: 'client_credentials',
      client_id: 'admin-cli',
      client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
    }), this.configOptions);
  }

  register(user: IUserRepresentation, adminAccessToken: string) {
    return axios.post(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/admin/realms/DigiCheck/users`, user, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + adminAccessToken},
      withCredentials: false});
  }

  login(username: string, password: string) {
    return axios.post(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/realms/DigiCheck/protocol/openid-connect/token`, qs.stringify({
      username,
      password,
      grant_type: 'password',
      client_id: 'digicheck-frontend',
    }), this.configOptions);
  }

  logout(refreshToken: string) {
    return axios.post(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/realms/DigiCheck/protocol/openid-connect/logout`, qs.stringify({
      refresh_token: refreshToken,
      client_id: 'digicheck-frontend',
    }), this.configOptions);
  }

  updateAccessToken(refreshToken: string){
    return axios.post(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/realms/DigiCheck/protocol/openid-connect/token`, qs.stringify({
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      client_id: 'digicheck-frontend',
    }), this.configOptions);
  }

  getUserByEmail(email: string, adminAccessToken: string){
    return axios.get(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/admin/realms/DigiCheck/users?email=${email}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + adminAccessToken}, withCredentials: false});
  }

  putExecuteActionsEmail(user: any, data: string[], adminAccessToken: string){
    return axios.put(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/admin/realms/DigiCheck/users/${user.id}/execute-actions-email?redirect_uri=${process.env.REACT_APP_BASE_URL}/login&lifespan=300&client_id=digicheck-frontend`,
      data, {
        headers: { 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + adminAccessToken,
        },
        withCredentials: false});
  }

  putSendVerifyEmail(user: any, adminAccessToken:  string){
    return axios.put(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/admin/realms/DigiCheck/users/${user.id}/send-verify-email?redirect_uri=${process.env.REACT_APP_BASE_URL}/profile&client_id=digicheck-frontend`,
      {}, {
        headers: { 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + adminAccessToken,
        },
        withCredentials: false,
      },
    );
  }

  getActualUserAccount(accesToken: string){
    return axios.get(`${process.env.REACT_APP_KEYCLOAK_SERVICE_BASE}/realms/DigiCheck/account`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accesToken}, withCredentials: false});
  }

}
