import './User.scss';
import login from 'assets/icons/circle_chevron@3x.png';
import {Link} from 'react-router-dom';
import logoutImg from 'assets/icons/abmelden@3x.png';
import AuthContext from 'context/AuthContext/authContext';
import {useContext} from 'react';
import {Col, Navbar, Row} from 'react-bootstrap';
import ConsultantLinkGenerator from '../../common/consultant-link-generator/ConsultantLinkGenerator';


export default function User() {
  const {authenticated, keyCloakProfile, initLogin, logout} = useContext(AuthContext);

  const doLogin = () => {
    if (!authenticated){
      initLogin();
    }
  };

  const doLogout = () => {
    logout();
  };

  const handleProfileClick = () => {
    localStorage.setItem('lastPage', '/profile');
  };

  const renderUserAuthInformation = () => {
    if (authenticated){
      return (
        <Navbar.Text className='user-wrapper logged-in-wrapper d-flex justify-content-between' >
          <Link to='/profile' className='user-name'>
            <div onClick={handleProfileClick}>{keyCloakProfile.firstName} {keyCloakProfile.lastName}</div>
          </Link>
          <div className='logout-wrapper' onClick={doLogout}>
            <img className='logout-icon mr-2' src={logoutImg} alt='' />
            Abmelden
          </div>
        </Navbar.Text>
      );
    } else {
      return (
        <Navbar.Text className='user-wrapper logged-out-inner-wrapper' onClick={doLogin}>
          <span className='divider'>|</span>
          Einloggen<img className='login-icon ml-2' src={login} alt='' />
        </Navbar.Text>
      );
    }
  };

  return (
    <Navbar.Collapse id='responsive-navbar-nav' className='justify-content-end'>
      <Row>
        <Col sm={12} lg='auto' className='consultant-link-wrapper'>
          <Navbar.Text>
            <ConsultantLinkGenerator />
          </Navbar.Text>
        </Col>
        <Col sm={12} lg='auto' className='login-logout-wrapper'>
          { renderUserAuthInformation() }
        </Col>
      </Row>
    </Navbar.Collapse>
  );
}
