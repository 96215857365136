import chevronForward from 'assets/icons/circle_chevron@3x.png';
import close from 'assets/icons/close@3x.png';
import info from 'assets/icons/information@3x.png';
import axios from 'axios';
import ButtonAbort from 'components/common/button/ButtonAbort';
import ButtonTransparent from 'components/common/button/ButtonTransparent';
import { EHttpStatusCode } from 'components/common/enums/EHttpStatusCode';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import ResetQuestionnaireModal from 'components/modal/ResetQuestionnaireModal';
import { uncheckedTopicExisting } from 'components/questionnaire/QuestionnaireUtil';
import AuthContext from 'context/AuthContext/authContext';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Image, Modal, Row} from 'react-bootstrap';
import IndustryCategoryService from 'services/IndustryCategoryService';
import ProfileService from 'services/ProfileService';
import QuestionnaireService from 'services/QuestionnaireService';
import { Analytics } from 'util/Analytics';
import { dateFormatted } from 'util/DateUtil';
import { useEffectOnInit } from 'util/helperFunctions';
import FurtherInterests from './furtherInterests/FurtherInterest';
import './TopicSelection.scss';

import landmarkGrey from 'assets/icons/landmark-grey@3x.png';
import laptopGrey from 'assets/icons/laptop-grey@3x.png';
import piggyBankGrey from 'assets/icons/piggy-bank-grey@3x.png';
import rocketGrey from 'assets/icons/rocket-grey@3x.png';
import shoppingCartGrey from 'assets/icons/shopping-cart-grey@3x.png';
import usersGrey from 'assets/icons/users-grey@3x.png';

export const categoryImages = [
  rocketGrey,
  landmarkGrey,
  usersGrey,
  shoppingCartGrey,
  piggyBankGrey,
  laptopGrey,
];

export default function TopicSelection(this: any, props: ITopicSelectionProps) {
  const [categories, setCategories] = useState(new Array<ICategory>());
  const [questionnaire, setQuestionnaire] = useState({} as IQuestionnaire);
  const [profile, setProfile] = useState({} as IProfile);
  const [selectedTopics, setSelectedTopics] = useState(new Array<string>());
  const [showModal, setShowModal] = useState(false);
  const [showModalCategory, setShowModalCategory] = useState({} as ICategory);
  const [showModalReset, setShowModalReset] = useState(false);
  const [modalContent, setModalContent] = useState({} as any);
  const [categoryDescriptions, setCategoryDescriptions] = useState({} as any);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { authenticated } = useContext(AuthContext);
  const [currentInterests, setCurrentInterests] = useState('');
  const [furtherInterests, setFurtherInterests] = useState('');

  useEffectOnInit(() => {
    window.scrollTo(0, 0);

    localStorage.setItem('lastPage', 'topic-selection');

    loadData();
    loadModalContent();
    loadModalCategoryDescription();

    const digicheckStateItem = localStorage.getItem('digiCheckState');
    if (digicheckStateItem) {
      const digiCheckState: IDigiCheckState = JSON.parse(digicheckStateItem);
      if (
        digiCheckState.questionnaire &&
        digiCheckState.questionnaire.categories
      ) {
        const selected = digiCheckState.questionnaire.categories
          .map((category) => category.topics)
          .reduce((allTopics, topics) => allTopics.concat(topics), [])
          .map((topic) => topic.uuid);
        setSelectedTopics(selected);
      }
    }
    Analytics.getInstance().onKeyfactsDone();
  });

  const loadData = () => {
    if (!authenticated) {
      loadProfileFromLocalStorage();
    } else {
      ProfileService.getOrCreateProfile()
        .then((res) => {
          setProfile(res.data);
          getCategories(res.data.industry.uuid);
        })
        .catch((err) => {
          console.error(err.message + ' in GET /users/profile/getOrCreate');
        });
      const questionnaireUuid = props.match.params.uuid;
      if (questionnaireUuid !== undefined) {
        QuestionnaireService.getQuestionnaireById(questionnaireUuid)
          .then((res) => {
            const loadedQuestionnaire: IQuestionnaire = res.data;
            setQuestionnaire(loadedQuestionnaire);

            const topicList: string[] = [];
            loadedQuestionnaire.categories.forEach((category) => {
              category.topics.forEach((topic) => {
                topicList.push(topic.uuid);
              });
            });
            setCurrentInterests(loadedQuestionnaire.furtherInterests);
            setSelectedTopics(topicList);
          })
          .catch((err) => {
            if (err.response.status === EHttpStatusCode.FORBIDDEN) {
              console.error(
                err.message + ' Unauthorized user in GET /questionnaires/{uuid}',
              );
            } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
              console.error(
                err.message +
                  ' Questionnaire not found in GET /questionnaires/{uuid}',
              );
            } else {
              console.error(err.message + ' in GET /questionnaires/{uuid}');
            }
          });
      }
    }
  };

  const loadModalContent = () => {
    axios.get('/content/modal-topics-content.json').then((res) => {
      setModalContent(res.data);
    });
  };

  const loadModalCategoryDescription = () => {
    axios.get('/content/modal-category-description.json').then((res) => {
      setCategoryDescriptions(res.data);
    });
  };

  const loadProfileFromLocalStorage = () => {
    const digiCheckStateItem = localStorage.getItem('digiCheckState');
    if (digiCheckStateItem) {
      const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
      if (digiCheckState.profile) {
        setProfile(digiCheckState.profile);
        setQuestionnaire(digiCheckState.questionnaire);
        getCategories(digiCheckState.profile.industry.uuid);
        if (digiCheckState?.questionnaire?.furtherInterests) {
          setCurrentInterests(digiCheckState.questionnaire.furtherInterests);
        } else {
          setCurrentInterests('');
        }
      } else {
        props.history.push('/keyfacts');
      }
    } else {
      props.history.push('/keyfacts');
    }
  };

  const getCategories = (industryUuid: string) => {
    IndustryCategoryService.getIndustryCategories(industryUuid)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        if (err.response.status === EHttpStatusCode.NOT_FOUND) {
          console.error(err.message + ' Industry not found in GET /industries');
        } else {
          console.error(err.message + ' in GET /industries');
        }
      });
  };

  const handleTopicCheck = (event) => {
    let currentSelectedTopics: string[] = Array.from(selectedTopics.values());
    if (event.target.checked) {
      if (currentSelectedTopics.indexOf(event.target.id) === -1) {
        currentSelectedTopics.push(event.target.id);
      }
    } else {
      currentSelectedTopics = currentSelectedTopics.filter(
        (value, index, arr) => {
          return value !== event.target.id;
        },
      );
    }
    setSelectedTopics(currentSelectedTopics);
  };

  const changeAllTopicsInCategory = (event) => {
    let currentSelectedTopics: string[] = Array.from(selectedTopics.values());
    const relevantCategory: ICategory = categories.filter(
      (item) => item.uuid === event.target.id,
    )[0];

    if (event.target.checked) {
      relevantCategory.topics.forEach((topic) => {
        if (currentSelectedTopics.indexOf(topic.uuid) === -1) {
          currentSelectedTopics.push(topic.uuid);
        }
      });
    } else {
      relevantCategory.topics.forEach((topic) => {
        currentSelectedTopics = currentSelectedTopics.filter(
          (value, index, arr) => {
            return value !== topic.uuid;
          },
        );
      });
    }
    setSelectedTopics(currentSelectedTopics);
  };

  const updateTopics = (): IQuestionnaire | undefined => {
    const digiCheckStateItem = localStorage.getItem('digiCheckState');
    if (digiCheckStateItem) {
      const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
      if (digiCheckState.questionnaire) {
        const digiCheckStateCategoriesUuids =
          digiCheckState.questionnaire.categories.map(
            (category) => category.uuid,
          );
        const selectedCategories: ICategory[] = categories
          .filter((category) => {
            const selected: ITopic[] = category.topics.filter(
              (topic) => selectedTopics.indexOf(topic.uuid) !== -1,
            );
            return selected.length > 0;
          })
          .map((category) => {
            const categoryIndex = digiCheckStateCategoriesUuids.indexOf(
              category.uuid,
            );
            const digiCheckStateCategory =
              digiCheckState.questionnaire.categories[categoryIndex];

            const topics = category.topics.filter(
              (topic) => selectedTopics.indexOf(topic.uuid) !== -1,
            );

            // avoid overriding already selected topics and statements
            if (!digiCheckStateCategory) {
              return { ...category, topics };
            } else {
              const digiCheckStateTopicsUuids =
                digiCheckStateCategory.topics.map((topic) => topic.uuid);
              return {
                ...category,
                topics: topics.map((topic) => {
                  const topicIndex = digiCheckStateTopicsUuids.indexOf(
                    topic.uuid,
                  );
                  if (topicIndex !== -1) {
                    return digiCheckStateCategory.topics[topicIndex];
                  } else {
                    return topic;
                  }
                }),
              };
            }
          });
        const newQuestionnaire: IQuestionnaire = {
          ...digiCheckState.questionnaire,
          categories: selectedCategories,
          furtherInterests,
        };
        localStorage.setItem(
          'digiCheckState',
          JSON.stringify({ ...digiCheckState, questionnaire: newQuestionnaire }),
        );
        setQuestionnaire(newQuestionnaire);
        return newQuestionnaire;
      }
    }
  };

  const createOrUpdateQuestionnaire = () => {
    if (!authenticated) {
      createOrUpdateQuestionnaireUnauthenticated();
    } else {
      createOrUpdateQuestionnaireAuthenticated();
    }
  };

  const createOrUpdateQuestionnaireAuthenticated = () => {
    if (
      questionnaire === undefined ||
      Object.keys(questionnaire).length === 0
    ) {
      createQuestionnaireAuthenticated();
    } else {
      updateQuestionnaireAuthenticated(questionnaire.uuid);
    }
  };

  const createQuestionnaireAuthenticated = () => {
    setIsButtonClicked(true);
    QuestionnaireService.postQuestionnaire({
      name: 'Fragebogen vom ' + dateFormatted(new Date()),
      topics: selectedTopics,
      furtherInterests,
    })
      .then((res) => {
        const questionnaireUuid = res.data.uuid;
        const topicUuid = res.data.categories[0].topics[0].uuid;
        props.history.push(
          '/questionnaire/' + questionnaireUuid + '/topics/' + topicUuid,
        );
      })
      .catch((err) => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(
            err.message + ' Unauthorized user in POST /questionnaires',
          );
        } else if (err.response.status === EHttpStatusCode.NOT_ACCEPTABLE) {
          console.error(
            err.message + ' Validation exception in POST /questionnaires',
          );
        } else {
          console.error(err.message + ' in POST /questionnaires');
        }
      });
  };

  const updateQuestionnaireAuthenticated = (questionnaireUuid: string) => {
    QuestionnaireService.putQuestionnaire(questionnaireUuid, {
      name: questionnaire.name,
      topics: selectedTopics,
      furtherInterests,
    })
      .then((res) => {
        const resQuestionnaire = res.data;
        const uncheckedTopics = uncheckedTopicExisting(resQuestionnaire);
        const _questionnaireUuid = resQuestionnaire.uuid;

        if (props.history.location.search !== '' && uncheckedTopics) {
          if (uncheckedTopics[0]) {
            props.history.push(
              '/questionnaire/' +
                _questionnaireUuid +
                '/topics/' +
                uncheckedTopics[1],
            );
          } else if (!uncheckedTopics[0]) {
            const resCategories = res.data.categories;
            const lastCategory = resCategories[resCategories.length - 1];
            const topics = lastCategory.topics;
            props.history.push(
              '/questionnaire/' +
                _questionnaireUuid +
                '/topics/' +
                topics[topics.length - 1].uuid,
            );
          }
        } else {
          const topicUuid = res.data.categories[0].topics[0].uuid;
          props.history.push(
            '/questionnaire/' + _questionnaireUuid + '/topics/' + topicUuid,
          );
        }
      })
      .catch((err) => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(
            err.message + ' Unauthorized user in PUT /questionnaires/{uuid}',
          );
        } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
          console.error(
            err.message +
              ' Questionnaire not found in PUT /questionnaires/{uuid}',
          );
        } else if (err.response.stats === EHttpStatusCode.NOT_ACCEPTABLE) {
          console.error(
            err.message + ' Validation exception in PUT /questionnaires/{uuid}',
          );
        } else {
          console.error(err.message + ' in PUT /questionnaires/{uuid}');
        }
      });
  };

  const createOrUpdateQuestionnaireUnauthenticated = () => {
    const newQuestionnaire = updateTopics();
    if (!newQuestionnaire) {
      getQuestionnaire();
    } else {
      const uncheckedTopics = uncheckedTopicExisting(newQuestionnaire);
      const _questionnaireUuid = newQuestionnaire.uuid;

      if (props.history.location.search !== '' && uncheckedTopics) {
        if (uncheckedTopics[0]) {
          props.history.push(
            '/questionnaire/' +
              _questionnaireUuid +
              '/topics/' +
              uncheckedTopics[1],
          );
        } else if (!uncheckedTopics[0]) {
          const newCategories = newQuestionnaire.categories;
          const lastCategory = newCategories[newCategories.length - 1];
          const topics = lastCategory.topics;
          props.history.push(
            '/questionnaire/' +
              _questionnaireUuid +
              '/topics/' +
              topics[topics.length - 1].uuid,
          );
        }
      } else {
        const topicUuid = newQuestionnaire.categories[0].topics[0].uuid;
        props.history.push(
          '/questionnaire/' + _questionnaireUuid + '/topics/' + topicUuid,
        );
      }
    }
  };
  const getQuestionnaire = () => {
    setIsButtonClicked(true);

    QuestionnaireService.generateNewQuestionnaire({
      name: 'Fragebogen vom ' + dateFormatted(new Date()),
      topics: selectedTopics,
      furtherInterests,
    })
      .then((res) => {
        const questionnaireUuid = res.data.uuid;
        const digiCheckStateItem = localStorage.getItem('digiCheckState');
        if (digiCheckStateItem) {
          const digiCheckState: IDigiCheckState =
            JSON.parse(digiCheckStateItem);
          localStorage.setItem(
            'digiCheckState',
            JSON.stringify({ ...digiCheckState, questionnaire: res.data }),
          );
        }

        const topicUuid = res.data.categories[0].topics[0].uuid;
        props.history.push(
          '/questionnaire/' + questionnaireUuid + '/topics/' + topicUuid,
        );
      })
      .catch((err) => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(
            err.message + ' Unauthorized user in POST /questionnaires',
          );
        } else if (err.response.status === EHttpStatusCode.NOT_ACCEPTABLE) {
          console.error(
            err.message + ' Validation exception in POST /questionnaires',
          );
        } else {
          console.error(err.message + ' in POST /questionnaires');
        }
      });
  };

  const isTopicInitialChecked = (topic: ITopic): boolean => {
    let isChecked: boolean = false;
    selectedTopics.forEach((selectedTopic) => {
      if (topic.uuid === selectedTopic) {
        isChecked = true;
        return null; // leave for each
      }
    });

    return isChecked;
  };

  const areAllTopicsInitialChecked = (categoryUuid: string): boolean => {
    const relevantCategory: ICategory = categories.filter(
      (item) => item.uuid === categoryUuid,
    )[0];

    return relevantCategory.topics.every((val) => {
      return selectedTopics.indexOf(val.uuid) !== -1;
    });
  };

  const disableNextButton = () => {
    if (
      selectedTopics === undefined ||
      selectedTopics.length < 1 ||
      isButtonClicked
    ) {
      return true;
    }
    return false;
  };

  const showTopicInfoModal = (category: ICategory) => {
    setShowModalCategory(category);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const getButtonText = () => {
    if (props.history.location.search !== '') {
      return 'Fortsetzen';
    }
    return 'Weiter';
  };

  const renderModalContent = () => {
    let modal: any;

    if (showModalCategory && modalContent[showModalCategory.name]) {
      const content = modalContent[showModalCategory.name];

      if (content) {
        modal = content.map((item) => {
          if (
            item.showOnlyIn !== undefined &&
            item.showOnlyIn.indexOf(profile.industry.name) === -1
          ) {
            return null;
          }

          return (
            <div key={item.key} className='pb-3'>
              <p className='mb-2 info-modal-headline'>{item.headline}</p>
              <p className='info-modal-content'>{item.content}</p>
            </div>
          );
        });
      }
    }

    return modal;
  };

  const renderBackButton = () => {
    const questionnaireUuid = props.match.params.uuid;
    let backButtonUrl = '/keyfacts';

    if (questionnaireUuid !== undefined) {
      backButtonUrl = backButtonUrl + '/' + questionnaireUuid;
    }

    return (
      <span
        onClick={() => {
          updateTopics();
        }}
      >
        <ButtonTransparent url={backButtonUrl} content='Zurück' />
      </span>
    );
  };

  if (Object.keys(profile).length !== 0 && categories.length !== 0) {
    const categoryCards = categories.map((category: ICategory, i: number) => (
      <Card className= 'margin-bottom' key={category.uuid}>
        <Card.Body>
          <Card.Title>
            <Container>
              <Row>
                <Col xs={8} sm={8} className='pl-0'>
                  <Image src={categoryImages[i]}
                    alt={category.name}
                  />
                </Col>
                <Col className='px-0 pt-1' xs={1} sm={1}>
                  <Image
                    className='select-all-info-icon'
                    src={info}
                    onClick={() => showTopicInfoModal(category)}
                  />
                </Col>
                <Col className='px-0' xs={3} sm={3}>
                  <Form.Check
                    custom
                    checked={areAllTopicsInitialChecked(category.uuid)}
                    id={category.uuid}
                    type='checkbox'
                    onChange={changeAllTopicsInCategory}
                    label='Alles'
                    className='ml-2'
                  />
                </Col>
              </Row>
            </Container>
            <p>{category.name}</p>
          </Card.Title>
          {category.topics.map((topic: ITopic) => (
            <Form.Check
              custom
              key={topic.uuid}
              onChange={handleTopicCheck}
              type='checkbox'
              id={topic.uuid}
              label={topic.name}
              checked={isTopicInitialChecked(topic)}
            />
          ))}
        </Card.Body>
      </Card>
    ));

    const firstColumnCards: any[] = [];
    const secondColumnCards: any[] = [];
    const thirdColumnCards: any[] = [];

    categoryCards.forEach((card, i) => {
      if (i === 0) {
        firstColumnCards.push(card);
      } else if (i === 1) {
        firstColumnCards.push(card);
      } else if (i === 2) {
        secondColumnCards.push(card);
      } else if (i === 3) {
        thirdColumnCards.push(card);
      } else if (i === 4) {
        secondColumnCards.push(card);
      } else if (i === 5) {
        secondColumnCards.push(card);
      }
    });

    return (
      <div className='topic-selection-wrapper'>
        <h5 className='question-heading'>
          Bitte wählen Sie die für Sie relevanten Themen aus, zu denen Sie
          <br></br>Lösungsvorschläge erhalten möchten.
        </h5>
        <Form className='topic-selection-form'>
          <Row>
            <Col sm={6} lg={4} className='px-2'>
              {firstColumnCards}
            </Col>
            <Col sm={6} lg={4} className='px-2'>
              {secondColumnCards}
            </Col>
            <Col sm={6} lg={4} className='px-2'>
              {thirdColumnCards}
              <FurtherInterests
                setFurtherInterests={setFurtherInterests}
                currentInterests={currentInterests}
              />
            </Col>
          </Row>
        </Form>

        <Row>
          <Col
            md={{ order: 1, span: 6 }}
            xs={{ order: 2, span: 12 }}
            className='mt-5 mt-sm-0'
          >
            <ButtonAbort setShowModal={setShowModalReset} />
          </Col>
          <Col
            md={{ order: 2, span: 6 }}
            xs={{ order: 1, span: 12 }}
            className='text-md-right oposite-side-buttons'
          >
            {renderBackButton()}
            <Button
              variant='danger'
              className='default-button'
              onClick={createOrUpdateQuestionnaire}
              disabled={disableNextButton()}
            >
              {getButtonText()}
              <img
                src={chevronForward}
                className='chevron-forward'
                alt={getButtonText()}
              />
            </Button>
          </Col>
        </Row>

        {showModal ? (
          <Modal
            show={showModal}
            onHide={hideModal}
            animation={false}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header translate={'yes'}>
              <Modal.Title className='info-modal-title'>
                {showModalCategory.name}
              </Modal.Title>
              <img src={close} alt='' onClick={hideModal}></img>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className='pb-3'>
                  <p>
                    {categoryDescriptions[showModalCategory.name].description}
                  </p>
                </div>
                {renderModalContent}
              </>
            </Modal.Body>
          </Modal>
        ) : null}
        <ResetQuestionnaireModal
          showModal={showModalReset}
          setShowModal={setShowModalReset}
          history={props.history}
        ></ResetQuestionnaireModal>
      </div>
    );
  }
  return <LoadingSpinner text='Lade Themen' />;
}
