import ProfileInfo from '../profile-info/ProfileInfo';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import {useEffectOnInit} from 'util/helperFunctions';
import ButtonTransparent from 'components/common/button/ButtonTransparent';
import {useRef, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';


function EditProfile(props: IEditProfileProps) {
  const child = useRef<any>();
  const [isIndustryDefault, setIsIndustryDefault] = useState(true);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffectOnInit(() => {
    localStorage.setItem('lastPage', window.location.pathname);
  });

  const postUser = () => {
    setIsButtonClicked(true);
    child.current.postUser(true);
  };

  const resetButtonToEnabled = () => {
    setIsButtonClicked(false);
  };

  return (
    <div>
      <ProfileInfo
        ref={child}
        history={props.history}
        match={props.match}
        resetButtonToEnabled={resetButtonToEnabled}
        setIsIndustryDefault={setIsIndustryDefault}/>

      <Row>
        <Col md={12} className='text-right'>
          <ButtonTransparent url='/profile' content='Zurück' />
          <Button variant='danger' disabled={isIndustryDefault || isButtonClicked} onClick={() => postUser()}>
            Speichern
            <img src={chevronForward} className='chevron-forward' alt='Speichern' />
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default EditProfile;
