import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron } from 'react-bootstrap';

export default class TermsOfUse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='static-page-wrapper'>
        <h1>Nutzungsbedingungen des Digitalisierungs-Check</h1>

        <Jumbotron>
          <h2>1 Geltungsbereich</h2>
          <p>
            Für die Registrierung und Durchführung des Digitalisierungs-Checks gelten die nachfolgenden Nutzungsbedingungen.
            Für sämtliche Dienste der EXXETA AG unter <a href="https://s-digicheck.de/">s-digicheck.de</a> (im Folgenden: Dienste) gelten zusätzlich die
            <Link to='/privacy'> Datenschutzhinweise</Link> der EXXETA AG.
          </p>

          <h2>2 Registrierung</h2>
          <p>
            Die Nutzung des Dienstes auf <a href="https://s-digicheck.de/">s-digicheck.de</a> der EXXETA AG ist kostenlos.
            Um die Dienste vollständig zu nutzen (z.B. den Digitalisierungs-Check durchzuführen), ist eine Registrierung erforderlich. Als Nutzer verpflichten Sie sich,
            diese Dienste nur für sich selbst oder für einen Dritten berechtigterweise zu nutzen. Hierzu gehört, dass Nutzer sich nicht mit fremden Identitäten und/oder
            unter Nutzung von Daten Dritter registrieren und/oder anmelden, ohne hierzu berechtigt zu sein.<br/>
            Nutzer können sich neu registrieren oder mit einem bereits bestehenden Nutzerkonto für Dienste anmelden. Im Rahmen der Registrierung hat der Nutzer seine
            Anrede, seinen Vor- und Nachnamen, seine Email Adresse, sein Passwort, seine Telefonnummer und ggf. weitere Informationen anzugeben, die vom Nutzer im Rahmen
            des Registrierungsprozesses abgefragt werden. Aus Gründen der Sicherheit und um Missbrauch des Nutzerkontos vorzubeugen, sollte ein ausreichend sicheres
            Passwort gewählt werden. Nutzer müssen ihre Passwörter geheim halten und dürfen diese nicht an Dritte weitergeben.<br/>
            Mit Registrierung und Nutzung der Dienste der EXXETA AG erklärt sich der Nutzer mit der Geltung dieser Nutzungsbedingungen einverstanden. Im Falle eines
            Verstoßes gegen diese Nutzungsbedingungen behält sich EXXETA AG vor, das Nutzerkonto vorübergehend oder dauerhaft – einschließlich der jeweiligen Inhalte -
            zu sperren und/oder dauerhaft zu löschen.
          </p>

          <h2>3 Haftung</h2>
          <p>
            EXXETA AG betreibt die Website des Digitalisierungs-Checks („<a href="https://s-digicheck.de/">s-digicheck.de</a>“) in Kooperation mit der Landesbank Berlin AG/
            Berliner Sparkasse (im Folgenden: Kreditinstitut). Es besteht kein Anspruch auf die dauerhafte Verfügbarkeit der Website.<br/>
            Bis zu der Datenübermittlung an das Kreditinstitut ist EXXETA AG Datenverantwortlicher, im und ab dem Zeitpunkt der Datenübermittlung ist das Kreditinstitut
            Datenverantwortlicher. Es gelten die <Link to='/privacy'>Datenschutzhinweise</Link>.<br/>
            Der Nutzer hat keinen Anspruch darauf, dass die angegebenen Informationen tatsächlich seine unternehmerische Digitalisierung fördern.
          </p>

          <h2>4 Löschung des Nutzerkontos</h2>
          <p>
            Nutzer können das Nutzerkonto des Digitalisierung-Checks jederzeit ohne Angabe von Gründen löschen. Sofern Sie die Löschung Ihres Nutzerkontos
            wünschen, senden Sie bitte eine E-Mail an <a href='mailto:datenschutz@s-digicheck.de'>datenschutz@s-digicheck.de</a>. Nach erfolgreichem Eingang des Löschbegehrens werden sämtliche Informationen sowie
            das Nutzerkonto unwiderruflich gelöscht.<br/>
            Bitte beachten Sie, dass durch die wirksame Löschung des Nutzerkontos die Rechtmäßigkeit der Verwendung ihrer Daten für die Vergangenheit nicht beeinträchtigt wird.
          </p>

          <h2>5 Widerruf</h2>
          <p>
            Eine bereits erteilte Einwilligung können Sie jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an <a href='mailto:datenschutz@s-digicheck.de'>datenschutz@s-digicheck.de</a>.
            Weitere Informationen finden Sie dazu in den <Link to='/privacy'> Datenschutzhinweisen</Link>.
          </p>

          <h2>6 Rechte und Marken</h2>
          <p>
            Diese Seiten und Inhalte unterliegen dem Urheberrecht. Die in diesem Internet-Auftritt enthaltenen Kennzeichen wie beispielsweise Marken, insbesondere
            Produktnamen und Unternehmenskennzeichen sind gesetzlich geschützt. EXXETA AG und das Kreditinstitut tragen Sorge dafür, dass auf Seiten dieser Webpräsenz
            die Urheberrechte der verwendeten Grafiken und Texte beachtet werden. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und
            Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer.
          </p>

          <h2>7 Linkhinterlegung und Verweisungen</h2>
          <p>
            Bei dem Digitalisierungs-Check wird direkt und indirekt auf Angebote von externen Anbietern bzw. ihre Webseiten (sog. Hyperlinks) verwiesen. Es besteht kein Anspruch
            auf Nennung bzw. Bestand bestimmter externer Anbieter. Die EXXETA AG haftet nur für die Nennung des externen Anbieters und den Link, sofern sie Kenntnis von einem
            illegalen oder in sonstiger Weise rechtswidrigen Inhalt der Angebote und Webseiten des Anbieters hat oder künftig erhält. Es wird mithin ausdrücklich erklärt, dass in
            dem Zeitpunkt der Einstellung der Links keine Anhaltspunkte für solche Inhalte der Anbieter bestanden hat. Auf die künftige Gestaltung, die Inhalte oder die Urheberschaft
            der gelinkten/verknüpften Seiten hat die EXXETA AG keinerlei Einfluss.
          </p>

          <h2>8 Anwendbares Recht</h2>
          <p>
            Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
          </p>
        </Jumbotron>
      </div>
    );
  }
}
