import { Component } from 'react';
import { Button, Container } from 'react-bootstrap';
import { generateLinkOutURL } from 'util/LinkOutURLGenerator';
import starIconFull from 'assets/icons/star-full-white@3x.png';
import 'components/questionnaire/result/solution-partner/image-tag/ImageTag.scss';



export default class ProfileFavourite extends Component<IProfileFavouriteProps> {
  render() {
    return (
      <Container className='mb-3 favorite-wrapper mx-0 px-0'>
          <p className='mb-1 solution-partner-black'>{this.props.favourite.name}</p>
          <p className='mb-1 solution-partner-light'>{this.props.favourite.description}</p>
          <div className='favourite-button-position'>
            <a href={generateLinkOutURL(this.props.favourite.url, this.props.favourite.name)} className='solution-partner-more'
              onClick={() => this.props.onFavoriteClick(this.props.favourite)} target='_blank' rel='noopener noreferrer'>
                Zum Anbieter
            </a>
            <div className='image-tag-wrapper' onClick={() => this.props.deleteFavourite(this.props.favourite.uuid)}>
              <Button variant='danger' className='saved'>
                <span className='d-print-none'>als Favorit gespeichert</span>

                <img src={starIconFull}
                  alt='als Favorit gespeichert' />
              </Button>
            </div>
          </div>
      </Container >
    );
  }
}
