
import star from 'assets/icons/star-full-white@3x.png';
import Contact from 'components/common/contact/Contact';
import editIcon from 'assets/icons/edit@3x.png';
import maleIcon from 'assets/icons/male@3x.png';
import Favourite from './favourite/Favourite';
import ButtonRed from 'components/common/button/ButtonRed';
import briefcase from 'assets/icons/briefcase@3x.png';
import femaleIcon from 'assets/icons/female@3x.png';
import AuthContext from 'context/AuthContext/authContext';
import ProfileRating from './profile-rating/ProfileRating';
import ProfileKeyfact from './profile-keyfact/ProfileKeyfact';
import ProfileService from 'services/ProfileService';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import {useEffectOnInit} from 'util/helperFunctions';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import QuestionnaireService from 'services/QuestionnaireService';
import SolutionPartnerService from 'services/SolutionPartnerService';
import {useContext, useState} from 'react';
import {Card, CardDeck, Col, Image, Row} from 'react-bootstrap';
import './Profile.scss';

export default function Profile(props: IProfileProps) {
  const [questionnaires, setQuestionnaires] = useState(new Array<IQuestionnaire>());
  const [profile, setProfile] = useState({} as IProfile);
  const [solutionPartnerFavoriteList, setSolutionPartnerFavoriteList] = useState(new Array<ISolutionPartner>());
  const [profileLoadDone, setProfileLoadDone] = useState(false);
  const [noProfileFound, setNoProfileFound] = useState(true);
  const {authenticated, setPrefilledContactUrl, keyCloakProfile, initLogin} = useContext(AuthContext);

  useEffectOnInit(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('lastPage', window.location.pathname);
    if (authenticated) {
      setPrefilledContactUrl();
      if (keyCloakProfile) {
          loadData();
        } else {
          initLogin();
        }
    } else {
      console.error('Unauthorized...');
      initLogin();
    }
  });

  const loadData = () => {
    ProfileService.getOrCreateProfile()
      .then(res => {
        setProfile(res.data);
        setProfileLoadDone(true);
        setNoProfileFound(false);
      })
      .catch(err => {
        if (err?.response?.status === EHttpStatusCode.FORBIDDEN) {
          console.error(err.message + ' Unauthorized user in GET /users/profile/getOrCreate');
        } else if (err?.response?.status === EHttpStatusCode.NOT_FOUND) {
          console.warn(err.message + ' No profile found in GET /users/profile/getOrCreate -> set noProfileFound true');
          setNoProfileFound(true);
          setProfileLoadDone(true);
        } else {
          console.error(err.message + ' in GET /users/profile/getOrCreate');
        }
      });

    QuestionnaireService.getQuestionnaires()
      .then(res => {
          setQuestionnaires(res.data);
      })
      .catch(err => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(err.message + ' Unauthorized user in GET /questionnaires');
        } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
          console.warn(err.message + ' No questionnaire found in GET /questionnaires -> set noQuestionnaireFound true');
        } else {
          console.error(err.message + ' in GET /questionnaires');
        }
      });

    getFavourites();
  };

  const getFavourites = () => {
    SolutionPartnerService.getFavorites()
      .then(res => {
        setSolutionPartnerFavoriteList(res.data);
      })
      .catch(err => {
        if (err.response.status === EHttpStatusCode.FORBIDDEN) {
          console.error(err.message + ' Unauthorized user in GET  /favorites/solution-partners');
        } else {
          console.error(err.message + ' in GET /favorites/solution-partners');
        }
      });
  };

  const onFavoriteClick = (solutionPartner: ISolutionPartner) => {
    QuestionnaireService.putSolutionPartnerClickCount(solutionPartner.questionnaireUuid, solutionPartner.uuid)
      .catch(err => {
        console.error(
          err.message +  ' in PUT /questionnaires/' + solutionPartner.questionnaireUuid + '/solution_partners/' + solutionPartner.uuid);
      });
  };

  const deleteFavourite = (solutionPartnerUuid: string) => {
    SolutionPartnerService.deleteFavorite(solutionPartnerUuid)
      .then(res => {
        getFavourites();
      })
      .catch(err => {
        console.error(err);
      });
  };

  const editProfile = () => {
    props.history.push('/profile/edit');
  };

  const showOperatingSystems = (operatingSystems: string[]) => {
    if (operatingSystems && operatingSystems.length > 0) {
      return operatingSystems.join(', ');
    } else {
      return '-';
    }
  };

  const calculatePercentageScore = (firstCompleted: any) => {
    if (firstCompleted) {
      const scorePercentage: number = Number((firstCompleted.totalScore / 100).toFixed(1));
      return scorePercentage.toLocaleString(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    } else {
      return null;
    }
  };

  const renderAvatar = () => {
    const profilAttributes = keyCloakProfile.attributes;
    let avatarIcon = femaleIcon;

    if (profilAttributes !== undefined && profilAttributes.salutation !== undefined && profilAttributes.salutation.length === 1 && profilAttributes.salutation[0] === 'Herr') {
      avatarIcon = maleIcon;
    }

    return (<Image className='float-left mr-3 profile-avatar' src={avatarIcon} roundedCircle />);
  };

  const firstCompletedQuestionnaire = questionnaires.find(q => q.completed);

  const favorites: JSX.Element[] = solutionPartnerFavoriteList.map(fav =>
    <Favourite
      key={fav.uuid}
      favourite={fav}
      onFavoriteClick={onFavoriteClick}
      deleteFavourite={deleteFavourite}>
    </Favourite>,
  );

  if (authenticated) {
    if (profileLoadDone) {
      return (
        <div className='profile-wrapper'>
          <CardDeck className='mb-3'>
            <Card>
              <Card.Body className='contact-tile'>
                {renderAvatar()}
                <div className='profile-header-red'>
                  <div className='header-text'>
                    Guten Tag, {keyCloakProfile.attributes?.salutation ? keyCloakProfile.attributes?.salutation[0] : '' } {keyCloakProfile.lastName}!
                  </div>
                </div>
              </Card.Body>
            </Card>
          </CardDeck>
          <CardDeck className='mb-3'>
            <Contact profile={profile} isProfile />

            <Card className='ml-sm-2 mr-sm-3'>
              {
                firstCompletedQuestionnaire ?
                  <Card.Body>
                    <div className='float-left mb-3'>
                      Ihr aktueller Digitalisierungsgrad liegt bei {calculatePercentageScore(firstCompletedQuestionnaire)} von 4.
                    </div>
                    <ButtonRed url={'/keyfacts'} content={'Erneut Digitalisierungs-Check starten'} />
                  </Card.Body>
                  :
                  <Card.Body>
                    <div className='float-left mb-3'>Es liegt bisher noch kein Digitalisierungsgrad vor.</div>
                    <ButtonRed url={'/keyfacts'} content={'Digitalisierungscheck starten'} />
                  </Card.Body>
              }
            </Card>
          </CardDeck>

          <CardDeck className='mb-3'>
            <Card className='ml-sm-3 mr-sm-2'>
              <Card.Body >
                <Card.Title>
                  <div className='profile-header-red'>Ihre Unternehmensdaten</div>
                </Card.Title>
                {
                  !noProfileFound ?
                    <div>
                      <ProfileKeyfact keyfactName='Unternehmensname:' keyfactValue={profile.companyName || '-'}></ProfileKeyfact>
                      <ProfileKeyfact keyfactName='Branche:' keyfactValue={profile.industry?.name || '-'}></ProfileKeyfact>
                      <ProfileKeyfact keyfactName='Mitarbeiteranzahl:' keyfactValue={profile.numberEmployees || '-'}></ProfileKeyfact>
                      <ProfileKeyfact keyfactName='Betriebssystem(e):'
                                      keyfactValue={showOperatingSystems(profile.operatingSystems)}></ProfileKeyfact>
                    </div>
                    :
                    <div>
                      <Row className={'mb-4'}>
                        <Col xs={2}>
                          <div className={'error-image-wrapper d-flex align-items-center justify-content-center'}>
                            <Image className={'error-image'} src={briefcase} alt={''} />
                          </div>
                        </Col>
                        <Col className={'error-message my-auto'} xs={10}>Sie haben bisher noch keine Unternehmensdaten hinterlegt.</Col>
                      </Row>
                      <ProfileKeyfact keyfactName='Unternehmensname:' keyfactValue={'Keine Angabe'}></ProfileKeyfact>
                      <ProfileKeyfact keyfactName='Branche:' keyfactValue={'Keine Angabe'}></ProfileKeyfact>
                      <ProfileKeyfact keyfactName='Mitarbeiteranzahl:' keyfactValue={'Keine Angabe'}></ProfileKeyfact>
                      <ProfileKeyfact keyfactName='Betriebssystem(e):' keyfactValue={'Keine Angabe'}></ProfileKeyfact>
                    </div>
                }
                <div className='mt-2 top-right'>
                  <Image src={editIcon} alt='' onClick={() => editProfile()} />
                </div>
              </Card.Body>
            </Card>

            <Card className='ml-sm-2 mr-sm-3'>
              <Card.Body>
                <Card.Title>
                  <div className='profile-header-red'>Ihre gemerkten Lösungsoptionen</div>
                </Card.Title>
                {favorites.length > 0 ?
                  <div>
                    {favorites}
                  </div>
                  :
                  <div>
                    <Row className={'mb-4'}>
                      <Col xs={2} sm={4} md={2} lg={2}>
                        <div className={'error-image-wrapper d-flex align-items-center justify-content-center'}>
                          <Image className={'error-image'} src={star} alt={''} />
                        </div>
                      </Col>
                      <Col className={'error-message my-auto'} xs={10} sm={8} md={10} lg={10}>
                        Wir haben bisher noch keine Lösungsoptionen für Sie.
                      </Col>
                    </Row>
                    <p>Sie erhalten Lösungsoptionen, sobald Sie das erste Mal den Digitalisierungs-Check durchgeführt haben.</p>
                  </div>
                }
              </Card.Body>
            </Card>
          </CardDeck>

          <Card className='profile-rating-wrapper'>
            <Card.Body>
              <ProfileRating profile={profile} questionnaires={questionnaires} history={props.history}></ProfileRating>
            </Card.Body>
          </Card>
        </div >
      );
    } else {
      return (
        <LoadingSpinner text='Lade Profil' />
      );
    }
  }
  return (
    <LoadingSpinner text='Authentifizierung' />
  );
}
