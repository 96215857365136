import './ResetPassword.scss';
import {Link} from 'react-router-dom';
import AuthContext from 'context/AuthContext/authContext';
import {Button, Form} from 'react-bootstrap';
import DigicheckAuthAlert from 'components/alert/DigicheckAuthAlert';
import {useContext, useState} from 'react';


const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const {resetPassword, showEmailSent} = useContext(AuthContext);

  const handleResetPassword = () => {
    if (email !== ''){
      resetPassword(email);
    }
  };

    return(
      <div className='reset-password-form'>
        <div className='title'>
        <h1>Sie haben Ihre Zugangsdaten zum Digitalisierungscheck vergessen oder verlegt? Kein Problem!</h1>
          <p>Bitte geben Sie hier Ihre E-Mailadresse an. Sie erhalten in Kürze eine E-Mail von uns mit weiteren Anweisungen,
          wie Sie Ihr Kennwort zurücksetzen können.<br/><br/>
          <b>Bitte überprüfen Sie Ihren Posteingang und gegebenenfalls auch Ihren Spam-Ordner.</b></p>
        </div>
        { showEmailSent &&
        <DigicheckAuthAlert messages={['E-Mail wurde erfolgreich versandt.']} success={true} />
        }
        <Form className='keyfacts-form'>
            <Form.Group controlId='profileForm.email'>
              <Form.Label>E-Mail-Adresse:</Form.Label>
              <Form.Control type='text' value={email} onChange={event => setEmail(event.target.value)}/>
            </Form.Group>
            <Button variant='danger' onClick={handleResetPassword}>
              Passwort zurücksetzen
            </Button>
            <div className='login-link'>
              <span><Link to='/login'>Zurück zum Login</Link></span>
            </div>
        </Form>
      </div>
    );
};

export default ResetPassword;
