import axios, {AxiosResponse} from 'axios';

class QuestionnaireService {

  getQuestionnaires = async (): Promise<AxiosResponse> => {
    return axios.get(process.env.REACT_APP_API_BASE + '/questionnaires');
  }

  postQuestionnaire = async (questionnaire: ICreateQuestionnaire): Promise<AxiosResponse> => {
    return axios.post(process.env.REACT_APP_API_BASE + '/questionnaires', questionnaire);
  }

  generateNewQuestionnaire = async (questionnaire: ICreateQuestionnaire): Promise<AxiosResponse> => {
    return axios.post(process.env.REACT_APP_API_BASE + '/questionnaires/get', questionnaire, { withCredentials: false});
  }

  getQuestionnaireById = async (uuid: string): Promise<AxiosResponse> => {
    return axios.get(process.env.REACT_APP_API_BASE + '/questionnaires/' + uuid);
  }

  putQuestionnaire = async (uuid: string, questionnaire: ICreateQuestionnaire): Promise<AxiosResponse> => {
    return axios.put(process.env.REACT_APP_API_BASE + '/questionnaires/' + uuid, questionnaire);
  }

  postStatements =  async (questionnaireUuid: string, topicUuid: string,  statements: IHandleStatement): Promise<AxiosResponse> => {
    return axios.post(process.env.REACT_APP_API_BASE + '/questionnaires/' + questionnaireUuid + '/topics/' + topicUuid + '/statements', statements);
  }

  postQuestionnaireComplete = async (questionnaire: IDigiCheckState): Promise<AxiosResponse> => {
    return axios.post(process.env.REACT_APP_API_BASE + '/questionnaires/complete', questionnaire);
  }

  postQuestionnaireUncompleted = async(questionnaire: IDigiCheckState): Promise<AxiosResponse> => {
    return axios.post(`${process.env.REACT_APP_API_BASE}/questionnaires/uncompleted`, questionnaire);
  }

  putQuestionnaireComplete = async (uuid: string): Promise<AxiosResponse> => {
    return axios.put(process.env.REACT_APP_API_BASE + '/questionnaires/' + uuid + '/complete');
  }

  putSolutionPartnerClickCount = async (questionnaireUuid: string | undefined, solutionpartnerUuid: string): Promise<AxiosResponse> => {
    return axios.put(process.env.REACT_APP_API_BASE + '/questionnaires/' + questionnaireUuid + '/solution_partners/' + solutionpartnerUuid);
  }
}
export default new QuestionnaireService();
