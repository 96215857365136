import './Score.scss';
import print from 'assets/icons/print-white@3x.png';
import ResultChart from './ResultChart';
import { Component } from 'react';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import ScoreCategoryItem from './ScoreCategoryItem';
import ScoreCategoryAverage from './ScoreCategoryAverage';
import { Button, Col, Jumbotron, ListGroup, Row } from 'react-bootstrap';


const GRADE_TYPES = {
  'EXPLORER': {
    'title': 'Erkunder',
    'description': 'Sie schauen sich gerade in der Welt der Digitalisierung um und haben möglicherweise schon erste Maßnahmen umgesetzt - weiter so.',
  },
  'ADVANCED': {
    'title': 'Fortgeschrittener',
    'description': 'Super - Digitalisierungsthemen spielen heute schon eine größere Rolle für Sie und Sie setzen einige digitale Maßnahmen um.',
  },
  'PROFESSIONAL': {
    'title': 'Profi',
    'description': 'Wow, Sie sind ein Digitalisierungs-Profi. Digitalisierung spielt eine zentrale Rolle in Ihrem Unternehmen und Sie haben schon einen Großteil Ihrer Prozesse digitalisiert. Machen Sie weiter so!',
  },
  'FORERUNNER': {
    'title': 'Vorreiter',
    'description': 'Ihr Unternehmen zählt zu der digitalen Avantgarde. Ihr Unternehmen ist fast vollständig digitalisiert und Sie nutzen vollständig integrierte und intelligente Systeme.',
  },
};

export default class Score extends Component<IScoreProps, IScoreState> {

  constructor(props: IScoreProps) {
    super(props);

    this.state = {
      scrollPositionY: 0,
    };

    this.renderScoringCategories = this.renderScoringCategories.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const boundingRect = document.getElementsByClassName('result-score-wrapper jumbotron')[0].getBoundingClientRect();
    const offset = boundingRect.y;
    const height = boundingRect.height;

    this.setState({ scrollPositionY: (offset + height + 13 + 170) });
  }

  handlePrint() {
    window.print();
  }

  handleShowResult() {
    this.props.history.push('/questionnaire/' + this.props.questionnaire.uuid + '/result');
  }

  handleScroll() {
    window.scrollTo(0, this.state.scrollPositionY);
  }

  handleScrollToTop() {
    window.scrollTo(0, 0);
  }

  getClassNameCol() {
    if (this.props.isProfilePage) {
      return 'col-profile';
    }
    return 'col-score';
  }

  getClassNameButtonA() {
    if (this.props.isProfilePage) {
      return 'button-show';
    }
    return 'button-hide';
  }

  getClassNameButtonB() {
    if (this.props.isProfilePage) {
      return 'button-hide';
    }
    return 'button-show';
  }

  handleShowQuestionnaireResult() {
    this.props.history.push('/questionnaire/' + this.props.questionnaire.uuid + '/result');
  }

  renderScoringCategories() {
    if (this.props.questionnaire.scorings === undefined) {
      return (null);
    }

    const scoreCategoryItem = this.props.questionnaire.scorings.map(scoring => {
      return (
        <ScoreCategoryItem key={scoring.categoryUuid} categoryName={scoring.categoryName} scoring={scoring.score} />  
      );
    });

    return (
      <>
        <ListGroup>
          {scoreCategoryItem}
        </ListGroup>
        {
          this.props.questionnaire.averageScore && this.props.questionnaire.averageScore !== 0 ?
            <div>
              <ScoreCategoryAverage scoreAverage={this.props.questionnaire.averageScore} industryName={this.props.profile.industry.name}/>
            </div> : null
        }
      </>
    );
  }

  renderGrade() {
    if (this.props.questionnaire.gradeType === undefined) {
      return (null);
    }

    const gradeType = GRADE_TYPES[this.props.questionnaire.gradeType];

    return (
      <div>
        <h2>{gradeType['title']}</h2>
        <p>{gradeType['description']}</p>
      </div>
    );
  }
  
  render() {
    return (
      <Jumbotron className='result-score-wrapper'>
        <Row className='result-score-headline'>
          <Col>
            { this.props.profile.industry.name === 'Heilberufe' ?
                <h1>Digitaler Reifegrad Ihrer Praxis</h1>
                :
                <h1>Digitaler Reifegrad Ihres Unternehmens</h1>
            }
          </Col>
        </Row>
        <Row className='result-score-body'>
          <Col md={4} className='result-score-chart mb-4'>
            <ResultChart score={this.props.questionnaire.totalScore} scoreAverage={this.props.questionnaire.averageScore}/>
          </Col>
          <Col md={8} className='result-score-categories' >
            {this.renderScoringCategories()}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.renderGrade()}
          </Col>
        </Row>

        <Row className='score-buttons-wrapper d-print-none'>
          <Col className={this.getClassNameCol()}>
            <Button className='buttonLeft' variant='danger' onClick={this.handlePrint.bind(this)}>
              Digitalisierungs-Report drucken
              <img src={print} className='chevron-forward' alt='Digitalisierungs-Report drucken' />
            </Button>
          </Col>
          <Col className='text-md-right'>
            <Button className={this.getClassNameButtonA()} variant='danger' onClick={this.handleScroll}>
              Zu den Handlungsoptionen
              <img src={chevronForward} className='chevron-forward' alt='Zu den Handlungsoptionen' />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col className='text-right'>
            <Button className={this.getClassNameButtonB()} variant='danger' onClick={this.handleShowQuestionnaireResult.bind(this)}>
              Zur Auswertung
              <img src={chevronForward} className='chevron-forward' alt='Zur Auswertung' />
            </Button>
          </Col>
        </Row>
      </Jumbotron>
    );
  }
}
