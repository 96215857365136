import './Header.scss';
import User from './user/User';
import logo from 'assets/logos/spk-exxeta-logo-desktop@1x.png';
import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';


const Header: React.FC = () => {
  return (
    <header>
      <Navbar collapseOnSelect expand='lg' variant='dark'>
        <Navbar.Brand>
          <Link to='/start' className='header-link'>
            <img src={logo} className='image-large' alt='EXXETA - Digitalisierungs-Check - In Kooperation mit der Berliner Sparkasse' />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <User />
      </Navbar>
    </header>
  );
};
export default Header;