import ReactGA from 'react-ga4';

declare function isAnalyticsAllowed(): any;
declare function initializeEventListeners(): any;
declare function showScreenAdvanced(): any;

export class Analytics {

  private static _analyticsInstance: Analytics;

  static getInstance() {
    return this._analyticsInstance || (this._analyticsInstance = new this());
  }

  initAnalytics() {
    if (isAnalyticsAllowed()) {
      window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE}`] = false;
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE || '');
      ReactGA.set({ anonymizeIp: true });
      //ReactGA.pageview(window.location.pathname + window.location.search);
    } else {
      window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE}`] = true;
    }
  }

  showConsentScreenAdvanced() {
    showScreenAdvanced();
  }

  initEventListeners() {
    // define global function
    const _global = (window /* browser */ || global /* node */) as any;
    _global.initAnalytics = this.initAnalytics;
    initializeEventListeners();
  }

  onWebsiteOpenedNotAuthenticated() {
    setTimeout(() => {
      ReactGA.event({
        category: 'Navigation',
        action: '1. Startseite (Nicht authentifiziert)',
        nonInteraction: true,
      });
    }, 1000);
  }

  onDigiCheckStart() {
    ReactGA.event({
      category: 'User',
      action: 'Digi Check start button clicked',
      label: 'User',
    });
  }

  onDigiCheckStarted() {
    if (!localStorage.getItem('digicheckStartedFirstTime')) {
      ReactGA.event({
        category: 'User',
        action: 'Digi Check started first time',
        label: 'User',
      });
      localStorage.setItem('digicheckStartedFirstTime', 'true');
    } else if (!localStorage.getItem('digicheckStartedAgain')) {
      ReactGA.event({
        category: 'User',
        action: 'Digi Check started again',
        label: 'User',
      });
      localStorage.setItem('digicheckStartedFirstTime', 'false');
      localStorage.setItem('digicheckStartedAgain', 'true');
    }
  }

  onKeyfactsDone() {
    if (!localStorage.getItem('keyfactsDone')) {
      ReactGA.event({
        category: 'User',
        action: '2. Keyfacts Seite',
        label: 'Keyfacts',
      });
      localStorage.setItem('keyfactsDone', 'true');
    }
  }

  onTopicSelectionDone() {
    if (!localStorage.getItem('TopicSelectionDone')) {
      ReactGA.event({
        category: 'User',
        action: '3. Topic Selection Seite',
        label: 'Topic Selection',
      });
      localStorage.setItem('TopicSelectionDone', 'true');
    }
  }

  onQuestionnaireDone() {
    if (!localStorage.getItem('questionnaireDone')) {
      ReactGA.event({
        category: 'User',
        action: '5. Ergebnisse eingesehen',
        label: 'Questionnaire',
      });
      localStorage.setItem('questionnaireDone', 'true');
    }
  }

  onToSummaryClick() {
    console.log('track summary');
    ReactGA.event({
      category: 'User',
      action: '4. Fragebogen abgeschlossen',
      label: 'Summary',
    });
    localStorage.removeItem('digicheckStartedAgain');
    localStorage.removeItem('keyfactsDone');
    localStorage.removeItem('TopicSelectionDone');
    localStorage.removeItem('questionnaireDone');
  }

  onDigitizationAdviceButtonClicked() {
    console.log('track advice button');
    ReactGA.event({
      category: 'User',
      action: '(Authenticated) Digitization advice button clicked',
      label: 'User',
    });
  }
}
