import axios from 'axios';
import {useState} from 'react';
import AuthContext from './authContext';
import {digiHistory} from 'util/DigiHistory';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import {KeyCloakService} from 'services/KeyCloakService';
import {useEffectOnInit} from 'util/helperFunctions';
import QuestionnaireService from 'services/QuestionnaireService';
import {buildContactPrefilledUrl} from 'util/ContactUrlGenerator';
import {Analytics} from '../../util/Analytics';


const AuthContextProvider = (props) => {
  const {children} = props;

  const [keyCloakProfile, setKeyCloakProfile] = useState({} as IKeyCloakProfile);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoginFailedAlert, setShowLoginFailedAlert] = useState(false);
  const [showRegistrationFailedAlert, setShowRegistrationFailedAlert] = useState(false);
  const [showEmailSent, setShowEmailSent] = useState(false);
  const keyCloakService = new KeyCloakService();

  useEffectOnInit(() => {
    const authState = localStorage.getItem('authState');
    if (authState) {
      const authStateItem: IAuthState = JSON.parse(authState);
      if (authStateItem.refresh_expires <= new Date()) {
        localStorage.removeItem('authState');
        localStorage.removeItem('keyCloakProfile');
      } else {
        setAuthenticated(true);

        const keyCloakState = localStorage.getItem('keyCloakProfile');
        if (keyCloakState) {
          setKeyCloakProfile(JSON.parse(keyCloakState));
        } else{
          getActualUserAccount(authStateItem.access_token);
        }
      }
    }
    setIsLoading(false);
  });

  const initLogin = () => {
    digiHistory.push('/login');
  };

  const initRegistration = () => {
    digiHistory.push('/registry');
  };

  const updateToken = (): Promise<unknown> => {
    const authState = localStorage.getItem('authState');
    return new Promise((resolve, reject) => {
      if (!authState){
        initLogin();
        reject('Not Authorized');
      }

      const authStateItem: IAuthState = JSON.parse(authState!);
      if (new Date(authStateItem.expires).getTime() > new Date().getTime()){
        resolve(authStateItem.access_token);
      } else if (new Date(authStateItem.refresh_expires).getTime() > new Date().getTime()) {

        keyCloakService.updateAccessToken(authStateItem.refresh_token).then(
          res => {
            const data: IAuthResponse = res.data;
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            setAuthenticated(true);
            setAuthState(data);
            resolve(data.access_token);
          }).catch(err => {
          console.error(err.message + ' Failed to refresh the token');
          setAuthenticated(false);
          initLogin();
          reject(err);
        });
      } else {
        initLogin();
        reject('Not Authorized');
      }
    });
  };

  const login = (username: string, password: string, history: any) => {
    keyCloakService.login(username, password).then(res => {
      const data: IAuthResponse = res.data;
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      setAuthenticated( true);
      setAuthState(data);
      getActualUserAccount(data.access_token);
      const digiCheckStateItem = localStorage.getItem('digiCheckState');
      const path: string | null = localStorage.getItem('lastPage');
      if (digiCheckStateItem) {
        const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
        if (digiCheckState.questionnaire) {
          if (path === '/questionnaire/null/summary') {
            QuestionnaireService.postQuestionnaireComplete(digiCheckState).then(questionnaireRes => {
              Analytics.getInstance().onToSummaryClick();
              const completedQuestionnaire: IQuestionnaire = questionnaireRes.data;
              localStorage.removeItem('digiCheckState');
              localStorage.removeItem('registrationTriggered');
              const newPath = `/questionnaire/${completedQuestionnaire.uuid}/result`;
              localStorage.setItem('lastPage', newPath);
              history.push(newPath);
            })
              .catch(err => {
                if (err.response.stats === EHttpStatusCode.NOT_ACCEPTABLE) {
                  console.error(err.message + ' Validation exception in POST /questionnaires/complete');
                } else {
                  console.error(err.message + ' in POST /questionnaires/complete');
                }
              });
          } else {
            QuestionnaireService.postQuestionnaireUncompleted(digiCheckState).then(questionnaireRes => {
              localStorage.removeItem('digiCheckState');
              localStorage.removeItem('registrationTriggered');
              const newPath = path?.replace('null', questionnaireRes.data.uuid);
              
              localStorage.setItem('lastPage', newPath!);
              history.push(newPath);
            })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          if (path) {
            history.push(path);
          } else {
            history.push('/profile');
          }
        }
      } else {
        if (path) {
          history.push(path);
        } else {
          history.push('/profile');
        }
      }
    }).catch(err => {
      setShowLoginFailedAlert(true);
      console.error(err.message + ' in Login');
    });
  };

  const setAuthState = (data: IAuthResponse) => {
    const expires = new Date();
    const refresh_expires = new Date();

    expires.setSeconds(expires.getSeconds() + data.expires_in - 2);
    refresh_expires.setSeconds(refresh_expires.getSeconds() + data.refresh_expires_in - 2);
    const iAuthState: IAuthState = {
      access_token: data.access_token,
      expires,
      refresh_expires,
      refresh_token: data.refresh_token,
    };
    localStorage.setItem('authState', JSON.stringify(iAuthState));
  };

  const getActualUserAccount = (token: string) => {
    keyCloakService.getActualUserAccount(token).then(accountRes => {
      const data: IKeyCloakProfile = accountRes.data;
      setKeyCloakProfile(data);
      localStorage.setItem('keyCloakProfile', JSON.stringify(data));
    }).catch(err => {
      console.error('Failed to load Profile');
    });
  };

  const getKeyCloakAccount = (): IKeyCloakProfile => {
    const profileState = localStorage.getItem('keyCloakProfile');
    if (profileState) {
      setKeyCloakProfile(JSON.parse(profileState));
      return keyCloakProfile;
    } else {
      return {} as IKeyCloakProfile;
    }
  };

  const register = (user: IUserRepresentation, history: any) => {
    keyCloakService.getAdminAccess().then(res => {
      const data: IAuthResponse = res.data;
      const token = data.access_token;

      keyCloakService.register(user, token).then(registryRes => {
        if (registryRes.status === 201) {
          login(user.email, user.credentials[0].value, history);
        }
      }).catch(err => {
        if (err.response.status === EHttpStatusCode.UNAUTHORIZED) {
          console.warn(err.message + ' Invalid user credentials');
        } else if (err.response.status === EHttpStatusCode.BAD_REQUEST) {
          console.warn(err.message + ' bad request');
        } else if (err.response.status === EHttpStatusCode.CONFLICT) {
          setShowRegistrationFailedAlert(true);
        } else {
          console.error(err.message + ' in register');
        }
      });
    }).catch(err => {
      console.error(err.message + ' in getAccess');
    });
  };

  const logout = () => {
    const authState = localStorage.getItem('authState');
    if (authState) {

      const authStateItem: IAuthResponse = JSON.parse(authState!);
      keyCloakService.logout(authStateItem.refresh_token).then(res => {
        if (res.status === 204) {
          setAuthenticated( false);
          setKeyCloakProfile({} as IKeyCloakProfile);
          localStorage.removeItem('keyCloakProfile');
          localStorage.removeItem('authState');
          window.location.href = '/login';
        }
      }).catch(err => {
        console.error(err.message + ' in Logout');
      });
    }
  };

  const setPrefilledContactUrl = () => {
    axios.get(process.env.REACT_APP_API_BASE + '/users/profile').then(backendRes => {
      const backendProfile: IProfile = backendRes.data;

      buildContactPrefilledUrl(keyCloakProfile, backendProfile);
    }).catch(err => {
      if (err?.response?.status === EHttpStatusCode.NOT_FOUND) {
        console.warn(err.message + ' No profile found in GET /users/profile');
      } else {
        console.error(err.message + ' in GET /users/profile');
      }
    });
  };

  const resetPassword = (email: string) => {
    keyCloakService.getAdminAccess().then(res => {
      const data: IAuthResponse = res.data;
      const adminAccessToken = data.access_token;

      keyCloakService.getUserByEmail(email, adminAccessToken).then(getRes => {
        setShowEmailSent(true);
        const userData: any = getRes.data;

        keyCloakService.putExecuteActionsEmail(userData[0], ['UPDATE_PASSWORD'], adminAccessToken).catch(err => {
          if (err && err.message) {
            console.warn(err.message);
          }
          else {
            console.warn(err);
          }
        });
      }).catch(err => {
        if (err && err.message) {
          console.warn(err.message);
        }
        else {
          console.warn(err);
        }
      });
    });
  };

  const getFullName = () => {
    if (keyCloakProfile){

      return keyCloakProfile.firstName + ' ' + keyCloakProfile.lastName;
    }
    return '';
  };

  const getLastName = () => {
    if (keyCloakProfile !== undefined){

      return keyCloakProfile.lastName;
    }
    return '';
  };

  const handleShowLoginAlertChange = (value: boolean) => {
    setShowLoginFailedAlert(value);
  };
  const handleShowEmailSentChange = (value: boolean) => {
    setShowEmailSent(value);
  };

  return (
    <AuthContext.Provider value={{
      keyCloakProfile,
      accessToken,
      refreshToken,
      authenticated,
      isLoading,
      keyCloakService,
      initLogin,
      initRegistration,
      getActualUserAccount,
      updateToken,
      login,
      register,
      logout,
      setPrefilledContactUrl,
      getFullName,
      getLastName,
      resetPassword,
      showLoginFailedAlert,
      handleShowLoginAlertChange,
      showEmailSent,
      getKeyCloakAccount,
      handleShowEmailSentChange,
      showRegistrationFailedAlert
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
