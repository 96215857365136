import { Component } from 'react';
import { Col, ListGroup, ProgressBar, Row } from 'react-bootstrap';

export default class ScoreCategoryItem extends Component<IScoreCategoryItemProps> {
  
  render() {
    return (
      <ListGroup.Item className='result-score-category-item'>
        <Row>
          <Col md={7}>{this.props.categoryName}</Col>
          <Col md={5}>
            <ProgressBar now={this.props.scoring / 4} />
            <span className='progress-label'>
              {(this.props.scoring / 100).toLocaleString(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
              &nbsp;von erreichbaren 4
            </span>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}
