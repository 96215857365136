import info from "assets/icons/information@3x.png";
import close from "assets/icons/close@3x.png";
import axios from "axios";
import { Component } from "react";
import { Col, ListGroup, ProgressBar, Row, Image, Modal } from "react-bootstrap";

export default class ScoreCategoryAverage extends Component<any, any> {
  private industryName: string = this.props.industryName;
  private scoreAverage: number = Number(this.props.scoreAverage / 100);
  private benchmark = this.industryName === "Heilberufe" ? "Heilberufe" : "Üblicher Branchenwert";

  constructor(props: IScoreState) {
    super(props);

    this.state = {
      showModal: false,
      modalContent: {} as {(key: string): { title: string; description: string }},
    };
  }

  componentDidMount() {
    this.loadModalContent();
  }

  loadModalContent() {
    axios.get("/content/modal-category-result.json").then((res) => {
      this.setState({ modalContent: res.data });
    });
  }

  showModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  renderInfoIcon() {
    return (
      <Image
        src={info}
        className="select-all-info-icon positioning"
        title={this.state.modalContent[`${this.benchmark}`]?.description}
        onClick={this.showModal.bind(this)}
      />
    );
  }

  renderModalContent() {
    const description = this.state.modalContent[`${this.benchmark}`].description;
    return (
      <div className="pb-3">
        <p>{description}</p>
      </div>
    );
  }

  render() {
    const { showModal } = this.state;
    return (
      <ListGroup.Item className="result-score-category-item">
        <Row>
          <Col md={7}>Üblicher Branchenwert {this.renderInfoIcon()}</Col>

          {showModal ? (
            <Modal
              show={showModal}
              onHide={this.hideModal.bind(this)}
              animation={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header translate={"yes"}>
                <Modal.Title className="info-modal-title">
                  {this.state.modalContent["Üblicher Branchenwert"].title}
                </Modal.Title>
                <img
                  src={close}
                  alt="Üblicher Branchenwert"
                  onClick={this.hideModal.bind(this)}
                ></img>
              </Modal.Header>
              <Modal.Body>{this.renderModalContent()}</Modal.Body>
            </Modal>
          ) : null}

          <Col md={5} className={"benchmark-media-padding"}>
            <ProgressBar
              now={this.props.scoreAverage / 4}
              className="benchmark-progress"
            />
            <span className="progress-label">
              {this.scoreAverage}
              &nbsp; von erreichbaren 4
            </span>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}
