import AuthContext from 'context/AuthContext/authContext';
import ProfileService from 'services/ProfileService';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import IndustryCategoryService from 'services/IndustryCategoryService';
import {Col, Form, Jumbotron, Row} from 'react-bootstrap';
import {copyProfile, useEffectOnInit} from 'util/helperFunctions';
import {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';


const ProfileInfo = forwardRef((props: IProfileInfoProps, ref) => {
  const {resetButtonToEnabled, setIsIndustryDefault} = props;
  const [industries, setIndustries] = useState(new Array<IIndustry>());
  const { authenticated } = useContext(AuthContext);
  const [profile, setProfile] = useState({
    companyName: '',
    numberEmployees: '',
    industry: {
      uuid: '',
      name: '',
    },
    operatingSystems: new Array<string>(),
  } as IProfile);

  const industryElements = industries
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item: IIndustry) => (
      <option value={item.uuid} key={item.uuid}>
        {item.name}
      </option>
    ));

  useImperativeHandle(
    ref,
    () => ({
      postUser,
      profile,
    }));

  useEffectOnInit(() => {
    IndustryCategoryService.getIndustries().then( res => {
      setIndustries(res.data);
    }).catch(err => {
      console.error(err.message + ' in GET /industries');
    });
  });

  useEffect(() => {
    if (authenticated) {
      ProfileService.getOrCreateProfile().then(res => {
        if (
          res.data.companyName !== null
          && res.data.numberEmployees !== null
          && res.data.operatingSystems !== null
          && res.data.industry !== null
        ) {
          setProfile(res.data);
        }
      }).catch(err => {
        if (err?.response?.status === EHttpStatusCode.FORBIDDEN) {
          console.error(err.message + ' Unauthorized user in GET /users/profile/getOrCreate');
        } else {
          console.error(err.message + ' in GET /users/profile/getOrCreate');
        }
      });
    }
  }, [authenticated]);

  useEffect(() => {
    if (!profile.industry || profile.industry.name === '') {
      const digiCheckStateItem = localStorage.getItem('digiCheckState');
      if (digiCheckStateItem) {
        const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
        if (digiCheckState.profile) {
          setProfile(digiCheckState.profile);
          if (profile.industry) {
            setIsIndustryDefault(false);
          }
        }
      }
    } else {
      setIsIndustryDefault(false);
    }
  }, [profile]);

  const handleCompanyName = (event) => {
    const currentProfile = copyProfile(profile);
    currentProfile.companyName = event.target.value;
    setProfile(currentProfile);
  };

  const handleIndustryChange = (event) => {
    const value = event.target.value;
    if (value !== ''){
      setIsIndustryDefault(false);
    }
    const currentProfile = copyProfile(profile);
    currentProfile.industry = industries.filter(o  => o.uuid === value)[0];
    setProfile(currentProfile);
  };

  const handleNumberEmployeesChange = (event) => {
    const currentProfile = copyProfile(profile);
    currentProfile.numberEmployees = event.target.value;
    setProfile(currentProfile);
  };

  const handleOsChange = (event) => {
    const currentProfile = copyProfile(profile);
    if (currentProfile.operatingSystems === null || currentProfile.operatingSystems === undefined) {
      currentProfile.operatingSystems = [];
    }
    if (event.target.checked && !currentProfile.operatingSystems.includes(event.target.id)){
      currentProfile.operatingSystems.push(event.target.id);
    } else {
      currentProfile.operatingSystems = currentProfile.operatingSystems.filter((value, index, arr) => {
        return value !== event.target.id;
      });
    }
    setProfile(currentProfile);
  };

  const isOsSelected = (id: string): boolean  => {
    if (profile.operatingSystems !== null){
      return profile.operatingSystems.includes(id);
    }
    return false;
  };

  const navigate = (editProfile: boolean) => {
    if (editProfile) {
      props.history.push('/profile');
    } else {
      const questionnaireUuid = props.match.params.uuid;
      let nextUrl = '/topic-selection';
      if (questionnaireUuid !== undefined) {
        nextUrl = nextUrl + '/' + questionnaireUuid;
      }
      props.history.push(nextUrl);
    }
  };

  const postUser = (editProfile: boolean) => {
    if (profile.operatingSystems === null){
      profile.operatingSystems = [];
    } else {
      profile.operatingSystems = profile.operatingSystems.filter(os => os !== '');
    }

    if (!authenticated) {
      const digiCheckStateItem = localStorage.getItem('digiCheckState');
      if (digiCheckStateItem) {
        const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
        localStorage.setItem('digiCheckState', JSON.stringify({ ...digiCheckState, profile}));
      } else {
        localStorage.setItem('digiCheckState', JSON.stringify({ profile }));
      }
      navigate(editProfile);
    } else {
      ProfileService.postProfile(profile)
        .then(res => {
          setProfile(res.data);
          navigate(editProfile);
        })
        .catch(err => {
          resetButtonToEnabled();
          if (err.response.status === EHttpStatusCode.FORBIDDEN) {
            console.error(err.message + ' Unauthorized user in POST /users/profile');
          } else if (err.response.status === EHttpStatusCode.NOT_ACCEPTABLE) {
            console.error(err.message + ' Validation exception, i.e. companyName is empty, in POST /users/profile');
          } else if (err.response.status === EHttpStatusCode.INTERNAL_SERVER_ERROR) {
            console.error(err.message + ' Profile does already exist in POST /users/profile');
          } else {
            console.error(err.message + ' in POST /users/profile');
          }
        });
    }
  };

  if (industries && profile && profile.industry) {
    return (
      <Jumbotron>
        <Form className='keyfacts-form'>
          <button type='submit' disabled style={{ display: 'none' }} aria-hidden='true'></button>

          <Row>
            <Col md={6}>
              <Form.Group controlId='profileForm.industry'>
                <Form.Label>Wählen Sie die passende Branche Ihres<br></br>Unternehmens:</Form.Label>
                <Form.Control as='select' value={profile.industry.uuid} onChange={handleIndustryChange}>
                  <option disabled value=''> -- Branche -- </option>
                  {industryElements}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='profileForm.companyName'>
                <Form.Label>Wie lautet der Name Ihres<br></br>Unternehmens?</Form.Label>
                <Form.Control type='text' value={profile.companyName} placeholder='Unternehmen' onChange={handleCompanyName} />
              </Form.Group>

              <Form.Group controlId='profileForm.numberEmployees'>
                <Form.Label>Wie viele Mitarbeiter hat Ihr<br></br>Unternehmen in Deutschland?</Form.Label>
                <Form.Control as='select' value={profile.numberEmployees} onChange={handleNumberEmployeesChange}>
                  <option disabled value=''> -- Mitarbeiter -- </option>
                  <option value='0 - 4'>0 - 4</option>
                  <option value='5 - 9'>5 - 9</option>
                  <option value='10 - 20'>10 - 20</option>
                  <option value='21 - 50'>21 - 50</option>
                  <option value='51 - 100'>51 - 100</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>

              <Form.Group controlId='profileForm.question2'>
                <Form.Label className='m-0'>Welches Betriebssystem nutzen Sie in Ihrem<br></br>Unternehmen?</Form.Label>
                <p className='text-muted'>*Mehrfachnennung möglich</p>

                <Row>
                  <Col md={6}>
                    <Form.Check custom inline label='Microsoft Windows' type='checkbox' id='Microsoft Windows' checked={isOsSelected('Microsoft Windows')} onChange={handleOsChange} />
                  </Col>
                  <Col md={6}>
                    <Form.Check custom inline label='Android' type='checkbox' id='Android'
                                checked={isOsSelected('Android')} onChange={handleOsChange} />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Check custom inline label='MacOS' type='checkbox' id='MacOS'
                                checked={isOsSelected('MacOS')} onChange={handleOsChange} />
                  </Col>
                  <Col md={6}>
                    <Form.Check custom inline label='iOS' type='checkbox' id='iOS'
                                checked={isOsSelected('iOS')} onChange={handleOsChange} />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Check custom inline label='Linux' type='checkbox' id='Linux'
                                checked={isOsSelected('Linux')} onChange={handleOsChange} />
                  </Col>
                  <Col md={6}>
                    <Form.Check custom inline label='Microsoft Phone' type='checkbox' id='Microsoft Phone' checked={isOsSelected('Microsoft Phone')} onChange={handleOsChange} />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>

        </Form>
      </Jumbotron>
    );
  }
  return (
    <LoadingSpinner text='Lade Profil' />
  );
});
export default ProfileInfo;
