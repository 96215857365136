
export function uncheckedTopicExisting(questionnaire: IQuestionnaire): [boolean, string] {
  const result: [boolean, string] = [false, ''];

  // stops if first topic is found
  result[0] = questionnaire.categories.some((category) => {
    return category.topics.some((topic) => {

      // checks if all statements in topic are unchecked. if so --> returns true
      if (topic.statements.every(v => v.selected === false)) {
        result[1] = topic.uuid;
        return true;
      } else {
        return false;
      }
    });
  });

  return result;
}
