import {useContext} from 'react';
import AuthContext from 'context/AuthContext/authContext';

const Test = () => {
  const {authenticated, keyCloakProfile} = useContext(AuthContext);

  return (
    <div>
    <h1>{authenticated ? 'JA' : 'NEIN'}</h1>
    <h2>Guten Tag {keyCloakProfile.attributes.salutation[0]} {keyCloakProfile.lastName}!</h2>
    </div>
  );
};

export default Test;
