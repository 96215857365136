import './Topic.scss';
import info from 'assets/icons/information@3x.png';
import axios from 'axios';
import close from 'assets/icons/close@3x.png';
import {Component} from 'react';
import {Form, Image, Modal} from 'react-bootstrap';

export default class Topic extends Component<ITopicProps, ITopicState> {
  constructor(props: ITopicProps) {
    super(props);

    const { topic } = this.props;
    const isStepByStep: boolean = topic.type === 'STEP_BY_STEP';

    const disabledStatements: Record<string, boolean> = {};
    topic.statements.forEach((item: IStatement) => disabledStatements[item.uuid] = true);
    disabledStatements[topic.statements[0].uuid] = false;
    disabledStatements[topic.statements[topic.statements.length - 1].uuid] = false;

    this.state = {
      isStepByStep,
      disabledStatements,
      showModal: false,
      modalContent: [] as any,
      modalTopicsContent: [] as any,
    };
  }

  componentDidMount() {
    this.loadModalContent();
    this.loadModalTopicsContent();
  }

  loadModalContent() {
    axios.get('/content/modal-topic-icons-content.json').then(res => {
      this.setState({ modalContent: res.data });
    });
  }

  loadModalTopicsContent() {
    axios.get('/content/modal-topics-content.json').then(res => {
      this.setState({ modalTopicsContent: res.data });
    });
  }

  onChangeShowAll(e: any) {
    const { topic } = this.props;

    const indexOfStatement = topic.statements.map((x: IStatement) => 'statement-' + x.uuid).indexOf(e.target.id);
    const statementsToUncheck: IStatement[] = new Array<IStatement>();

    if (topic.statements[indexOfStatement + 1] === undefined) {

      /*if last checkbox is clicked (no statement applies):
        all other statements get unchecked (this is handled in parent component Question) */

      for (let i = 0; i < indexOfStatement; i++) {
        statementsToUncheck.push(topic.statements[i]);
      }
    } else {

      /*if any other checkbox is clicked:
        last statement get unchecked (this is handled in parent component Question) */

      const lastStatement = topic.statements[topic.statements.length - 1];
      statementsToUncheck.push(lastStatement);
    }

    this.props.handleUnselectFollowingStatements(statementsToUncheck);
    this.props.onChange(e);
  }

  onChangeStepByStep(e: any) {
    const { topic } = this.props;
    const { disabledStatements } = this.state;

    const indexOfStatement = topic.statements.map((x: IStatement) => 'statement-' + x.uuid).indexOf(e.target.id);

    if (topic.statements[indexOfStatement + 1] === undefined) {

      /*if last checkbox is clicked (no statement applies):
        other statements get disabled and also unchecked (this is handled in parent component Question) */

      const statementsToUncheck: IStatement[] = new Array<IStatement>();
      for (let i = 0; i < indexOfStatement; i++) {
        disabledStatements[topic.statements[i].uuid] = true;
        statementsToUncheck.push(topic.statements[i]);
      }
      this.props.handleUnselectFollowingStatements(statementsToUncheck);

    } else if (e.target.checked) {

      /*if one of the previous statements is clicked
        following statement is activated*/

      disabledStatements[topic.statements[indexOfStatement + 1].uuid] = false;
      const statementsToUncheck: IStatement[] = new Array<IStatement>();
      statementsToUncheck.push(topic.statements[topic.statements.length - 1]);
      this.props.handleUnselectFollowingStatements(statementsToUncheck);

    } else {

      /*if one of the previous statements is unclicked
        all following statements have to be disabled and unchecked*/

      const statementsToUncheck: IStatement[] = new Array<IStatement>();
      for (let i = indexOfStatement; i < topic.statements.length - 1; i++) {
        disabledStatements[topic.statements[i + 1].uuid] = true;
        statementsToUncheck.push(topic.statements[i + 1]);
      }
      this.props.handleUnselectFollowingStatements(statementsToUncheck);

    }

    disabledStatements[topic.statements[0].uuid] = false;
    disabledStatements[topic.statements[topic.statements.length - 1].uuid] = false;

    this.props.onChange(e);
    this.setState({ disabledStatements });
  }

  showTopicInfoModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  renderInfoIcon() {
    if (this.state.modalContent === undefined || this.state.modalContent.length === 0) {
      return (null);
    }

    return (
      <Image src={info} className='select-all-info-icon' alt={this.props.topic.name} onClick={this.showTopicInfoModal.bind(this)} />
    );
  }

  renderModalSubHeader() {
    const categoryArray: string[] = [];
    Object.keys(this.state.modalTopicsContent).forEach((key) => {
      categoryArray.push(key);
    });

    let categoryName = '';
    for (let i = 0; i < categoryArray.length; ++i) {
      if (this.state.modalTopicsContent[categoryArray[i]].find(element => element.headline === this.props.topic.name) !== undefined) {
        categoryName = categoryArray[i];
        break;
      }
    }

    const description = this.state.modalTopicsContent[categoryName].find(element => element.headline === this.props.topic.name).content;

    return (
      <div className='pb-3'>
        <p>{description}</p>
      </div>);
  }

  renderModalContent() {
    let modal: any;

    if (this.state.modalContent) {
      modal = this.state.modalContent.map((item, i) => {
        if (item.showOnlyIn.indexOf(this.props.topic.name) === -1) {
          return (null);
        }

        return (
          <div key={`topic-modal-info-${i}`} className='pb-3'>
            <p className='mb-2 info-modal-headline'>{item.headline}</p>
            <p className='info-modal-content'>{item.content}</p>
          </div>
        );
      });
    }

    return modal;
  }

  render() {
    const { topic, isQuestionnaireCompleted } = this.props;
    const { isStepByStep, disabledStatements, showModal } = this.state;

    let statements;

    if (isQuestionnaireCompleted) {
      statements = topic.statements.map((item: IStatement) =>
        <Form.Check custom disabled={true} checked={item.selected}
          type='checkbox' id={`statement-${item.uuid}`} key={`statement-${item.uuid}`} label={item.content} />,
      );
    } else if (isStepByStep) {
      topic.statements.forEach((item: IStatement, index) => {
        if (topic.statements[index + 1] !== undefined && item.selected) {
          disabledStatements[topic.statements[index + 1].uuid] = false;
        } else if (topic.statements[index + 1] !== undefined) {
          disabledStatements[topic.statements[index + 1].uuid] = true;
        }
      });
      disabledStatements[topic.statements[0].uuid] = false;
      disabledStatements[topic.statements[topic.statements.length - 1].uuid] = false;

      statements = topic.statements.map((item: IStatement) =>
        <Form.Check disabled={disabledStatements[item.uuid]} custom checked={item.selected} onChange={this.onChangeStepByStep.bind(this)} type='checkbox' id={`statement-${item.uuid}`} key={`statement-${item.uuid}`} label={item.content} />,
      );
    } else {
      statements = topic.statements.map((item: IStatement) =>
        <Form.Check custom checked={item.selected} onChange={this.onChangeShowAll.bind(this)} type='checkbox' id={`statement-${item.uuid}`} key={`statement-${item.uuid}`} label={item.content} />,
      );
    }

    return (
      <div>
        <h6 className='title-icon-position'>
          {topic.name}
          {this.renderInfoIcon()}
        </h6>
        <p className='text-muted'>
          * Mehrfachnennung möglich
        </p>

        <Form>
          {statements}
        </Form>

        {showModal ?
          <Modal show={showModal} onHide={this.hideModal.bind(this)}
            animation={false} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header translate={'yes'}>
              <Modal.Title className='info-modal-title'>{topic.name}</Modal.Title>
              <img src={close} alt='' onClick={this.hideModal.bind(this)}></img>
            </Modal.Header>
            <Modal.Body>
              {this.renderModalSubHeader()}
              {this.renderModalContent()}
            </Modal.Body>
          </Modal>
          : null}
      </div>
    );
  }
}
