import './DigicheckAlert.scss';

const DigicheckAuthAlert = (props: IDigicheckAlertProps) => {
  const {messages, success} = props;

  return(
    <div className={'digicheck-auth-alert'}>
      { success ? <div className={'auth-success-sign'}></div>
        : <div className={'auth-error-sign'}></div>
      }
      <div className={'auth-alert-messages'}>
        {
          messages.map((message, index) => (
            <div key={index} className={'auth-alert-message'}>{message}</div>
          ))
        }
      </div>
    </div>
  );
};

export default DigicheckAuthAlert;
