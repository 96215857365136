import {IWizardProps} from 'model/components/IWizardProps';
import {IHash} from 'model/IHash';
import {Component} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import './Wizard.scss';

import landmarkWhite from '../../../assets/icons/landmark-white@3x.png';
import laptopWhite from '../../../assets/icons/laptop-white@3x.png';
import piggyBankWhite from '../../../assets/icons/piggy-bank-white@3x.png';
import rocketWhite from '../../../assets/icons/rocket-white@3x.png';
import shoppingCartWhite from '../../../assets/icons/shopping-cart-white@3x.png';
import usersWhite from '../../../assets/icons/users-white@3x.png';

// Workaround to get dynamic icons for each category
const categoryIcons: IHash = {};
categoryIcons['Online-Marketing & -Vertrieb'] = landmarkWhite;
categoryIcons['Kerngeschäft'] = rocketWhite;
categoryIcons['Digitales Personalmanagement'] = usersWhite;
categoryIcons['Digitale Warenwirtschaft'] = piggyBankWhite;
categoryIcons['Digitale Verwaltung und Finanzprozesse'] = shoppingCartWhite;
categoryIcons['IT-Management'] = laptopWhite;

export default class Wizard extends Component<IWizardProps> {
  wizardLineStyle(categoryIndex: number, numberCategories: number) {
    const windowWidth = window.innerWidth;
    let wizardLineWidth = 0;

    // Workaround to get dynamic length of red wizard line
    if (windowWidth >= 1200) {
      wizardLineWidth = (this.props.categories.length - 1) * 190;
    } else {
      let containerWidth;
      if (document.getElementById('wizard-container')) {
        containerWidth  = document.getElementById('wizard-container')?.offsetWidth;
      } else {
        containerWidth = 1;
      }

      const containerWidthDivider = windowWidth < 576 ? this.props.categories.length : 6;
      wizardLineWidth = this.props.categories.length === 5
        ? (this.props.categories.length - 1) * ( containerWidth / containerWidthDivider) - 50
        : (this.props.categories.length - 1) * ( containerWidth / containerWidthDivider);
    }

    const numberLines = numberCategories - 1;
    const redLinePercent = 100.0 / numberLines * categoryIndex;

    return {
      width: wizardLineWidth + 'px',
      backgroundImage: 'linear-gradient(90deg, #FF0000 ' + redLinePercent + '%, #CCCCCC 0%)',
    };
  }

  render() {
    let pastWizardItem = true;

    let items = this.props.categories.map((item) =>
      <Col xs={Math.round(12 / this.props.categories.length)} sm={2} md={2} key={item.uuid} className='wizard-item-wrapper'>
        {this.props.categories[this.props.currentCategoryIndex].uuid === item.uuid ? (
          <div className='wizard-item active'>
            <Image src={categoryIcons[item.name]} alt={item.name} />
            {pastWizardItem = false}
          </div>
        ) : (
            <div className={`wizard-item ${pastWizardItem ? 'past' : ''}`}>
              <Image src={categoryIcons[item.name]} alt={item.name} />
            </div>
          )}
        <p className='d-none d-lg-block'>{item.name}</p>
      </Col>,
    );

    if (this.props.finished) {
      items = this.props.categories.map((item) =>
        <Col xs={12 / this.props.categories.length} sm={2} md={2} key={item.uuid} className='wizard-item-wrapper'>
          <div className='wizard-item past'>
            <Image src={categoryIcons[item.name]} alt={item.name} />
            {pastWizardItem = false}
          </div>
          <p className='d-none d-lg-block'>{item.name}</p>
        </Col>,
      );
    }

    return (
      <Row id='wizard-container' className='wizard-container justify-content-center'>
        <div className='wizard-line ie-11-wizard'
          style={this.wizardLineStyle(this.props.currentCategoryIndex, this.props.categories.length)}>
        </div>
        {items}
      </Row>
    );
  }
}
