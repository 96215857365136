import './FurtherInterests.scss';
import close from 'assets/icons/close@3x.png';
import info from 'assets/icons/information@3x.png';
import clipboard from 'assets/icons/clipboard-list-check@3x.png';
import React, {useEffect, useState} from 'react';
import {Card, Col, Container, Image, Modal, Row} from 'react-bootstrap';

interface IInterface {
  setFurtherInterests: any;
  currentInterests: any;
}

const FurtherInterests = (props: IInterface): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState(props.currentInterests);
  useEffect(() => {
    props.setFurtherInterests(value);
  }, [value]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const hideModal = () => {
    setShowModal(false);
  };
  const showInfoModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <div className='card'>
        <Card.Body>
          <Card.Title>
            <Container>
              <Row>
                <Col xs={11} sm={11} className='pl-0'>
                  <Image src={clipboard} alt={'icon'}/>
                </Col>
                <Col className='px-0 pt-1' xs={1} sm={1}>
                  <Image className='select-all-info-icon'
                      src={info}
                      onClick={showInfoModal}
                  />
                </Col>
              </Row>
            </Container>
            <p>Weitere Themen</p>
          </Card.Title>
          <div className='more-topics'>
              <textarea className='textarea'
                placeholder='Haben Sie weitere Interessen, die wir nicht genannt haben z.B. Fragen zu Konto/Krediten,  Fuhrparkmanagement, digitale Arbeitsschutzlösungen oder digitale Reiseabrechnung? Dann fügen Sie diese hier gerne hinzu.'
                value={value}
                onChange={handleChange}/>
          </div>
        </Card.Body>
      </div>
      {showModal ?
        <Modal show={showModal} onHide={hideModal} animation={false} size='lg'
               aria-labelledby='contained-modal-title-vcenter' centered>
          <Modal.Header translate={'yes'}>
            <Modal.Title className='info-modal-title'>{'Weitere Themen'}</Modal.Title>
            <img src={close} alt='' onClick={hideModal}/>
          </Modal.Header>
          <Modal.Body>
            <div className='pb-3'>
              <p>Das für Sie relevante Thema war in der Übersicht nicht dabei? Nutzen Sie gerne das
                Freifeld, um
                das für Sie interessante mittels Texteingabe (Stichwort ausreichend) an uns zu
                übermitteln. Es
                kann sich hierbei auch um Finanzdienstleistungen handeln. Wir melden uns bei Ihnen
                zurück.</p>
            </div>
          </Modal.Body>
        </Modal>
        : null}
    </div>
  );
};
export default FurtherInterests;
