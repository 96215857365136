import Topic from './topic/Topic';
import Wizard from './wizard/Wizard';
import {Analytics} from 'util/Analytics';
import AuthContext from 'context/AuthContext/authContext';
import ButtonAbort from 'components/common/button/ButtonAbort';
import ButtonTopic from 'components/common/button/ButtonTopic';
import LoadingSpinner from 'components/common/loading-spinner/LoadingSpinner';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import ProfileService from 'services/ProfileService';
import chevronBackward from 'assets/icons/chevron-circle-right@3x.png';
import {EHttpStatusCode} from 'components/common/enums/EHttpStatusCode';
import QuestionnaireService from 'services/QuestionnaireService';
import {useContext, useState} from 'react';
import ResetQuestionnaireModal from 'components/modal/ResetQuestionnaireModal';
import {copyQuestionnaire, useEffectOnInit} from 'util/helperFunctions';
import {Button, Col, Jumbotron, ProgressBar, Row} from 'react-bootstrap';


export default function Question(props: IQuestionProps) {
  const questionnaireUuid = props.match.params.uuid;
  const [questionnaire, setQuestionnaire] = useState({} as IQuestionnaire);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [topicIndex, setTopicIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(1);
  const [showModalReset, setShowModalReset] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const {authenticated} = useContext(AuthContext);
  const [profile, setProfile] = useState({} as IProfile);

  useEffectOnInit(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('lastPage', window.location.pathname);
    loadQuestionnaire();
    Analytics.getInstance().onTopicSelectionDone();
  });

  const loadQuestionnaire = () => {
    if (!authenticated) {
      const digiCheckStateItem = localStorage.getItem('digiCheckState');
      if (digiCheckStateItem) {
        const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
        setQuestionnaire(digiCheckState.questionnaire);
        setProfile(digiCheckState.profile);
        setTotalSteps(getTotalSteps(digiCheckState.questionnaire));
        getCurrentQuestionnairePosition(digiCheckState.questionnaire);
      }
    } else {
      loadProfile();
      QuestionnaireService.getQuestionnaireById(questionnaireUuid)
        .then(res => {
          setQuestionnaire(res.data);
          setTotalSteps(getTotalSteps(res.data));
          getCurrentQuestionnairePosition(res.data);
        })
        .catch(err => {
          if (err.response.status === EHttpStatusCode.FORBIDDEN) {
            console.error(err.message + ' Unauthorized user in GET /questionnaires/{uuid}');
          } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
            console.error(err.message + ' Questionnaire not found in GET /questionnaires/{uuid}');
          } else {
            console.error(err.message + ' in GET /questionnaires/{uuid}');
          }
        });
    }
  };

  const getTotalSteps = (fromQuestionnaire: IQuestionnaire): number => {
    let steps: number = 0;
    if (fromQuestionnaire) {
      fromQuestionnaire.categories.forEach(category => {
        steps = steps + category.topics.length;
      });
    }
    return steps;
  };

  const getCurrentQuestionnairePosition = (fromQuestionnaire: IQuestionnaire) => {
    let step = 0;
    let cIndex = 0;
    let tIndex = 0;

    if (fromQuestionnaire) {

      fromQuestionnaire.categories.forEach(category => {
        category.topics.forEach(topic => {
          step++;

          if (props.match.params.topicUuid === topic.uuid) {
            setCurrentStep(step);
            setCategoryIndex(cIndex);
            setTopicIndex(tIndex);
          }

          tIndex++;
        });

        cIndex++;
        tIndex = 0;
      });
    }

    console.debug('Position has been determined: categoryIndex: ' + categoryIndex + ' | topicIndex: ' + topicIndex);
  };

  const previousItem = () => {
    if (questionnaire.completed) {
      previousItemQuestionnaireCompleted();
    } else {
      previousItemQuestionnaireUncompleted();
    }
  };

  const previousItemQuestionnaireCompleted = () => {
    if (questionnaire.categories[categoryIndex].topics[topicIndex - 1] != null) {
      console.debug('[Questionnaire completed] Previous topic exists in category');

      const previousTopic = questionnaire.categories[categoryIndex].topics[topicIndex - 1];
      props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + previousTopic.uuid);
    } else if (questionnaire.categories[categoryIndex - 1] != null) {
      console.debug('[Questionnaire completed] Previous topic does not exists in category, but previous category exists');

      const previousCategoryLastTopicIndex = questionnaire.categories[categoryIndex - 1].topics.length - 1;
      const previousTopic = questionnaire.categories[categoryIndex - 1].topics[previousCategoryLastTopicIndex];

      props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + previousTopic.uuid);
    } else {
      props.history.push('/profile');
    }
  };

  const previousItemQuestionnaireUncompleted = () => {
    if (questionnaire.categories[categoryIndex].topics[topicIndex - 1] != null) {
      console.debug('Previous topic exists in category');

      postCurrentStatements()
        .then(() => {
          const previousTopic = questionnaire.categories[categoryIndex].topics[topicIndex - 1];
          props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + previousTopic.uuid);
        });
    } else if (questionnaire.categories[categoryIndex - 1] != null) {
      console.debug('Previous topic does not exists in category, but previous category exists');

      postCurrentStatements()
        .then(() => {
          const previousCategoryLastTopicIndex = questionnaire.categories[categoryIndex - 1].topics.length - 1;
          const previousTopic = questionnaire.categories[categoryIndex - 1].topics[previousCategoryLastTopicIndex];

          props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + previousTopic.uuid);
        });
    } else {
      props.history.push('/topic-selection/' + questionnaireUuid);
    }
  };

  const postCurrentStatements = () => {
    const selectedStatements: string[] = questionnaire.categories[categoryIndex].topics[topicIndex].statements
      .filter(item => item.selected)
      .map(item => item.uuid);

    setIsButtonClicked(true);

    const category = questionnaire.categories[categoryIndex];

    const digiCheckStateItem = localStorage.getItem('digiCheckState');
    if (digiCheckStateItem) {
      const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
      localStorage.setItem('digiCheckState', JSON.stringify({ ...digiCheckState, questionnaire}));
    }

    if (authenticated) {
      return QuestionnaireService.postStatements(questionnaireUuid, category.topics[topicIndex].uuid, {'statements': selectedStatements})
      .then(res => {
        setQuestionnaire(res.data);
      })
        .catch(err => {
          if (err.response.status === EHttpStatusCode.FORBIDDEN) {
            console.error(err.message + ' Unauthorized user in POST /questionnaires/{uuid}/topics/{topicUuid}/statements');
          } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
            console.error(err.message + ' Questionnaire or Topic not found in POST /questionnaires/{uuid}/topics/{topicUuid}/statements');
          } else {
            console.error(err.message + ' in POST /questionnaires/{uuid}/topics/{topicUuid}/statements');
          }
        });
    }
    return new Promise((resolve) => { resolve(''); });
  };

  const nextItem = () => {
    if (questionnaire.completed) {
      nextItemQuestionnaireCompleted();
    } else {
      nextItemQuestionnaireUncompleted();
    }
  };

  const nextItemQuestionnaireCompleted = () => {
    if (questionnaire.categories[categoryIndex].topics[topicIndex + 1] != null) {
      console.debug('[Questionnaire completed] Next topic exists in category');

      const nextTopic = questionnaire.categories[categoryIndex].topics[topicIndex + 1];
      props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + nextTopic.uuid);
    } else if (questionnaire.categories[categoryIndex + 1] != null) {
      console.debug('[Questionnaire completed] Next topic does not exists in category, but next category exists');

      const nextTopic = questionnaire.categories[categoryIndex + 1].topics[0];
      props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + nextTopic.uuid);
    } else {
      console.debug('[Questionnaire completed] Next topic and next category does not exists, move to result page');

      if (questionnaire.completed) {
        props.history.push('/questionnaire/' + questionnaireUuid + '/result');
        return;
      }
    }
  };

  const loadProfile = () => {
    ProfileService.getOrCreateProfile()
    .then(res => {
      setProfile(res.data);
    })
    .catch(err => {
      if (err.response.status === EHttpStatusCode.FORBIDDEN) {
        console.error(err.message + ' Unauthorized user in GET /users/profile/getOrCreate');
      } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
        console.warn(err.message + ' No profile found in GET /users/profile/getOrCreate');
      } else {
        console.error(err.message + ' in GET /users/profile/getOrCreate');
      }
    });
  };

  const nextItemQuestionnaireUncompleted = () => {
    if (questionnaire.categories[categoryIndex].topics[topicIndex + 1] != null) {
      console.debug('Next topic exists in category');

      postCurrentStatements()
        .then(() => {
          const nextTopic = questionnaire.categories[categoryIndex].topics[topicIndex + 1];
          props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + nextTopic.uuid);
        });
    } else if (questionnaire.categories[categoryIndex + 1] != null) {
      console.debug('Next topic does not exists in category, but next category exists');
      postCurrentStatements()
        .then(() => {
          const nextTopic = questionnaire.categories[categoryIndex + 1].topics[0];
          props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + nextTopic.uuid);
        });
    } else {
      console.debug('Next topic and next category does not exists, move to summary page');

      postCurrentStatements()
        .then(() => {
          props.history.push('/questionnaire/' + questionnaireUuid + '/summary');
        });
    }
  };

  const handleStatementCheck = (event) => {
    const updatedQuestionnaire = copyQuestionnaire(questionnaire);
    const topic = questionnaire.categories[categoryIndex].topics[topicIndex];
    const index: number = topic.statements.map((x: IStatement) => 'statement-' + x.uuid).indexOf(event.target.id);
    if (index !== -1) {
      updatedQuestionnaire.categories[categoryIndex].topics[topicIndex].statements[index].selected = event.target.checked;
    }
    setQuestionnaire(updatedQuestionnaire);
  };

  const handleUnselectFollowingStatements = (statements: IStatement[]) => {
    const updatedQuestionnaire = copyQuestionnaire(questionnaire);
    statements.forEach(statement => {
      updatedQuestionnaire.categories[categoryIndex].topics[topicIndex].statements
        .filter(item => statement.uuid === item.uuid)
        .map(item => {
          return item.selected = false;
        });
    });
    setQuestionnaire(updatedQuestionnaire);
  };

  const disableNextButton = () => {
    const selected = questionnaire.categories[categoryIndex].topics[topicIndex].statements
      .find(item => item.selected);

    return selected === undefined || isButtonClicked;
  };

  const renderChangeTopics = (path: string) => {
    if (!questionnaire.completed) {
      return (<ButtonTopic url={path} />);
    }
  };

  if ((questionnaire !== undefined && Object.keys(questionnaire).length !== 0) && questionnaire.categories !== undefined) {
    const path = '/topic-selection/' + questionnaireUuid;

    return (
      <div className='question-wrapper'>
        <Wizard categories={questionnaire?.categories} currentCategoryIndex={categoryIndex} finished={false} />
        { profile.industry.name === 'Heilberufe' ?
            <h5 className='question-heading'>Geben Sie bitte einen Überblick, wie Ihre Praxis aktuell aufgestellt ist.</h5>
            :
            <h5 className='question-heading'>Geben Sie bitte einen Überblick, wie Ihr Unternehmen aktuell aufgestellt ist.</h5>
        }
        <ProgressBar now={((currentStep - 1) / totalSteps) * 100} />
        <Jumbotron>
          <p className='progress-label'>Fortschritt: {(((currentStep - 1) / totalSteps) * 100).toFixed(0)}%</p>
          <Topic topic={questionnaire.categories[categoryIndex].topics[topicIndex]} onChange={handleStatementCheck}
                 handleUnselectFollowingStatements={handleUnselectFollowingStatements}
                 isQuestionnaireCompleted={questionnaire.completed} />
        </Jumbotron>

        <Row>
          <Col md={{order: 1, span: 8}} xs={{order: 2, span: 12}} className='mt-5 mt-sm-0'>
            <Row>
              <Col xs={12} sm={6}>
                <div className='ml-sm-0 mb-2 mb-sm-0'><ButtonAbort setShowModal={setShowModalReset} /></div>
              </Col>
              <Col xs={12} sm={6}>
                {renderChangeTopics(path)}
              </Col>
            </Row>
          </Col>
          <Col md={{order: 2, span: 4}} xs={{order: 1, span: 12}}  className='text-md-right oposite-side-buttons'>
            <Button className='mr-3 default-button' variant='info' onClick={() => previousItem()}>
              <img src={chevronBackward} className='chevron-backward' alt='Zurück' />
              Zurück
            </Button>
            <Button className='default-button' variant='danger' onClick={() => nextItem()} disabled={disableNextButton()}>
              Weiter
              <img src={chevronForward} className='chevron-forward' alt='Weiter' />
            </Button>
          </Col>
        </Row>
        <ResetQuestionnaireModal showModal={showModalReset} setShowModal={setShowModalReset} history={props.history}></ResetQuestionnaireModal>
      </div>
    );
  }
  return (
    <LoadingSpinner text='Lade Fragebogen' />
  );
}
