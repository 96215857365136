
export function dateFormatted(date: Date): string {
  let day: string = date.getDate().toString();
  let month: string = (date.getMonth() + 1).toString();
  const year: string = date.getFullYear().toString();
  let hours: string = date.getHours().toString();
  let minutes: string = date.getMinutes().toString();

  day = day.length > 1 ? day : '0' + day;
  month = month.length > 1 ? month : '0' + month;
  hours = hours.length > 1 ? hours : '0' + hours;
  minutes = minutes.length > 1 ? minutes : '0' + minutes;

  return day + '.' + month + '.' + year + ', ' + hours + ':' + minutes + ' Uhr';
}
