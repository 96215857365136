import {KeycloakProfile} from 'keycloak-js';

const contactBaseUrl = 'https://www.berliner-sparkasse.de/fi/home/branchen-und-berufe/digitalisierungsberatung/antrag.html?n=true&stref=primarybutton';
export var contactPrefilledUrl = '';

export function buildContactPrefilledUrl(keycloakProfile: KeycloakProfile | undefined, profile: IProfile) {
  if (!keycloakProfile) {
    return;
  }
  const keycloakAttributes = keycloakProfile['attributes'];

  const firstName = keycloakProfile['firstName'] || '';
  const lastName = keycloakProfile['lastName'] || '';
  const email = keycloakProfile['email'] || '';

  const salutation = (keycloakAttributes && keycloakAttributes.salutation[0]) || '';
  const phoneNumber = (keycloakAttributes && keycloakAttributes.phoneNumber && keycloakAttributes.phoneNumber[0]) || '';

  var companyName = '';
  if (profile) {
    companyName = profile.companyName || '';
  }
  contactPrefilledUrl = `${contactBaseUrl}&ifa=Vorname:${firstName};Name:${lastName};EMail:${email};Anrede:${salutation};Firma:${companyName};Telefon:${phoneNumber}`;
}

export function getContactPrefilledUrl() {
  if (contactPrefilledUrl !== '') {
    return contactPrefilledUrl;
  }
  return contactBaseUrl;
}
