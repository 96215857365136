import './Contact.scss';
import questionsImg from 'assets/icons/undraw_questions@3x.png';
import { Component } from 'react';
import { Analytics } from 'util/Analytics';
import chevronForward from 'assets/icons/circle_chevron@3x.png';
import { Card, Media } from 'react-bootstrap';
import { generateLinkOutURL } from 'util/LinkOutURLGenerator';
import { getContactPrefilledUrl } from 'util/ContactUrlGenerator';

export default class Contact extends Component<IContactProps, IContactState> {

  constructor(props: IContactProps) {
    super(props);

    this.state = {
      consultantLink: getContactPrefilledUrl(),
    };
  }

  onClick() {
    Analytics.getInstance().onDigitizationAdviceButtonClicked();
    var generatedLink = generateLinkOutURL(getContactPrefilledUrl());
    this.setState({
      consultantLink : generatedLink
    });
  }

  render() {
    return (
      <Card className={`${this.props.isProfile ? 'ml-sm-3 mr-sm-2 ' : ''}contact-wrapper`}>
        <Card.Body>
          <Media>
            <div className='media-image-position'>
              <img height={100} src={questionsImg} alt='Zur Digitalisierungsberatung' />
            </div>
            <Media.Body>
              <p className='mt-3 mt-sm-0 custom-width'>Bei der Priorisierung und Umsetzung der Handlungsoptionen aus Ihrem Digitalisierungs-Check
                erhalten Sie gern Unterstützung durch die Digitalisierungsberater*innen der Berliner Sparkasse.</p>
              <a href={this.state.consultantLink} onClick={() => this.onClick()} className='button btn btn-danger' target='_blank' rel='noopener noreferrer'>
                Jetzt Beratungstermin vereinbaren
                <img src={chevronForward} className='chevron-forward' alt='Jetzt Beratungstermin vereinbaren' />
              </a>
            </Media.Body>
          </Media>
        </Card.Body>
      </Card>
    );
  }
}
