
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'App.scss';
import Test from 'components/test/Test';
import Login from 'components/login/Login';
import Result from 'components/questionnaire/result/Result';
import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import Imprint from 'components/footer/imprint/Imprint';
import Privacy from 'components/footer/privacy/Privacy';
import Profile from 'components/profile/Profile';
import Summary from 'components/questionnaire/summary/Summary';
import NotFound from 'components/error/not-found/NotFound';
import Keyfacts from 'components/keyfacts/Keyfacts';
import Question from 'components/questionnaire/Question';
import Registry from 'components/registry/Registry';
import TermsOfUse from 'components/footer/terms-of-use/TermsOfUse';
import {Container} from 'react-bootstrap';
import AuthContext from 'context/AuthContext/authContext';
import EditProfile from 'components/profile/edit-profile/EditProfile';
import {Analytics} from 'util/Analytics';
import LandingPage from 'components/landingPage/LandingPage';
import {digiHistory} from 'util/DigiHistory';
import InternalError from 'components/error/internal-error/InternalError';
import ResetPassword from 'components/resetPassword/ResetPassword';
import TopicSelection from 'components/topic-selection/TopicSelection';
import {useEffectOnInit} from 'util/helperFunctions';
import axios, {AxiosRequestConfig} from 'axios';
import React, {useContext, useState} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';

const App: React.FC = () => {

    const {authenticated, updateToken} = useContext(AuthContext);
    const [background, setBackground] = useState(false);

    useEffectOnInit(() => {
        checkForBackgroundImage();
    });

    const ifNotAuthenticated = () => {
        Analytics.getInstance().onWebsiteOpenedNotAuthenticated();
        return (<Redirect from='/' to='/start'/>);
    };

    axios.interceptors.request.use((config: AxiosRequestConfig) => {
        if ((config.url && config.url.startsWith('/content/')) || config.withCredentials === false) {
            return Promise.resolve(config);
        } else {
            return updateToken().then(token => {
              if (!config.headers) {
                config.headers = {};
            }
                config.headers.Authorization = 'Bearer ' + token;
                return Promise.resolve(config);
            });
        }
    });

    const checkForBackgroundImage = () => {
        const path = window.location.pathname;
        setBackground(path === '/login' || path === '/registry' || path === '/resetPassword');
    };

    digiHistory.listen(() => {
        checkForBackgroundImage();
    });

    return (
        <Router history={digiHistory}>
            <Header/>
            <div className={background ? 'background' : ''}>
                <Container className='mb-5 pt-5'>
                    <Switch>
                        <Route path='/login' component={Login}/>
                        <Route path='/registry' component={Registry}/>
                        <Route path='/resetPassword' component={ResetPassword}/>
                        <Route path={'/test'} component={Test}/>
                        <Route path='/start' component={LandingPage}/>
                        <Route path='/keyfacts/:uuid' component={Keyfacts}/>
                        <Route path='/keyfacts' component={Keyfacts}/>
                        <Route path='/topic-selection/:uuid' component={TopicSelection}/>
                        <Route path='/topic-selection' component={TopicSelection}/>
                        <Route path='/questionnaire/:uuid/topics/:topicUuid' render={(props) => (
                            <Question key={props.match.params.topicUuid} {...props} />
                        )}/>
                        <Route path='/questionnaire/:uuid/summary' component={Summary}/>
                        <Route path='/questionnaire/:uuid/result' component={Result}/>
                        <Route path='/questionnaire/:uuid' component={Question}/>
                        <Route path='/profile/edit' component={EditProfile}/>
                        <Route path='/profile' component={Profile}/>
                        <Route path='/imprint' component={Imprint}/>
                        <Route path='/privacy' component={Privacy}/>
                        <Route path='/terms-of-use' component={TermsOfUse}/>
                        <Route path='/404' component={NotFound}/>
                        <Route path='/500' component={InternalError}/>
                        {!authenticated ? ifNotAuthenticated() : <Redirect from='/' to='/profile'/>}
                    </Switch>
                </Container>
            </div>
            <Footer/>
        </Router>
    );
};

export default App;
