import ImageTag from '../image-tag/ImageTag';
import { Component } from 'react';
import { Image, Media } from 'react-bootstrap';
import { generateLinkOutURL } from 'util/LinkOutURLGenerator';
import './FurtherSolutionPartnerItem.scss';

export default class FurtherSolutionPartnerItem extends Component<ISolutionPartnerItemProps> {

  getClassName() {
    const className = 'favorite-icon-wrapper';

    if (this.props.solutionPartner.isFavorite) {
      return className + ' saved';
    }

    return className + ' unsaved';
  }

  render() {
    return (
      <div id={`collapse-${this.props.eventKey}`} className='result-further-solution-partner-item collapse'>
        <Media>
          <a href={generateLinkOutURL(this.props.solutionPartner.url, this.props.solutionPartner.name)} className='top-solution-link solution-partner-logo-link'
            onClick={() => this.props.onPartnerClick(this.props.solutionPartner)} target='_blank' rel='noopener noreferrer'>
              <Image src={`/images/${this.props.solutionPartner.imageName}`} className='solution-partner-logo' />
          </a>
          <Media.Body>
            <h3>
            <a href={generateLinkOutURL(this.props.solutionPartner.url, this.props.solutionPartner.name)} className='top-solution-link solution-partner-name'
              onClick={() => this.props.onPartnerClick(this.props.solutionPartner)} target='_blank' rel='noopener noreferrer'>
                {this.props.solutionPartner.name}
            </a>
            </h3>
            <p className='description'>{this.props.solutionPartner.description}</p>
            <div className='favourite-button-position'>
              <a href={generateLinkOutURL(this.props.solutionPartner.url, this.props.solutionPartner.name)} className='top-solution-link'
                onClick={() => this.props.onPartnerClick(this.props.solutionPartner)} target='_blank' rel='noopener noreferrer'>
                  Zum Anbieter
              </a>
              <ImageTag solutionPartner={this.props.solutionPartner} onFavoriteChange={this.props.onFavoriteChange} />
            </div>
          </Media.Body>
        </Media>
      </div>
    );
  }
}
