import axios, {AxiosResponse} from 'axios';

class ProfileService {

  getOrCreateProfile = async (): Promise<AxiosResponse> => {
    return axios.get(process.env.REACT_APP_API_BASE + '/users/profile/getOrCreate');
  }

  getProfile = async (): Promise<AxiosResponse> => {
    return axios.get(process.env.REACT_APP_API_BASE + '/users/profile');
  }

  postProfile = async (profile: IProfile): Promise<AxiosResponse> => {
    return axios.post(process.env.REACT_APP_API_BASE + '/users/profile', profile);
  }

}
export default new ProfileService();
