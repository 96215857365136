import ButtonRed from 'components/common/button/ButtonRed';
import AuthContext from 'context/AuthContext/authContext';
import {useContext} from 'react';
import internalError from 'assets/images/500@3x.png';


export default function InternalError() {
  const {authenticated} = useContext(AuthContext);
  const renderButton = () => {
    if (authenticated) {
      return (
        <ButtonRed url={'/profile'} content={'Zurück zum Profil'}></ButtonRed>
      );
    }
    return (
      <ButtonRed url={'/start'} content={'Zurück zum Start'}></ButtonRed>
    );
  };
  return (
      <div className={'mt-5 static-page-wrapper error-wrapper text-center'}>
        <img height={376} src={internalError} alt='' />
        <h1 className={'h1 error-header'}>Hoppla! Bei der Verbindung zu unserem Server ist ein Fehler aufgetreten.</h1>
        <div className={'error-description'}>Um wieder zur Startseite zu gelangen, klicken Sie den Button.</div>
        {renderButton()}
      </div>
    );
  }
