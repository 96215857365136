import Score from 'components/questionnaire/result/score/Score';
import analysis from 'assets/images/undraw_instat_analysis_ajld@3x.png';
import ButtonRed from 'components/common/button/ButtonRed';
import { Component } from 'react';
import LastQuestionnaire from '../last-questionnaire/LastQuestionnaire';
import { Card, Col, Row } from 'react-bootstrap';


export default class ProfileRating extends Component<IProfileRatingProps> {
  render() {
    const { questionnaires } = this.props;
    const firstCompletedQuestionnaire = questionnaires.find(q => q.completed === true);

    const lastQuestionnaires = questionnaires.map(q =>
      <LastQuestionnaire key={q.uuid} questionnaire={q} history={this.props.history}></LastQuestionnaire>,
    );

    return (
      <div className='result-wrapper result-profile-wrapper'>
        {lastQuestionnaires.length > 0 ?
          <div>
            {
              firstCompletedQuestionnaire
                ? <div>
                  <Score profile={this.props.profile} questionnaire={firstCompletedQuestionnaire} showPrint={false} history={this.props.history} isProfilePage={true} />
                  <hr />
                </div>
                : null}
            <Card.Title>
              <div className='profile-header-red'>Ihre letzten Digitalisierungsfragebögen</div>
            </Card.Title>
            {lastQuestionnaires}
          </div>
          :
          <div>
            <h1>Ihr Digitalisierungsgrad</h1>
            <p>Derzeit liegt uns noch keine Auswertung Ihres Digitalisierungsgrades vor.</p>
            <p className={'mt-3'}>
              Nehmen Sie sich für den Digitalisierungs-Check 20 Minuten Zeit und finden Sie heraus,
              wie digital Ihr Unternehmen aufgestellt ist.
            </p>
            <Row className={'mt-4'}>
              <Col xs={6}>
                <img className='analysis-image' src={analysis} alt='' />
              </Col>
              <Col className={'pl-4 my-auto'} xs={6}>
                <div className={'small-header'}>Was ist der Digitalisierungsgrad?</div>
                <div className={'mt-3 mb-4'}>
                  Der Self-Check betrachtet im Folgenden die Auswirkungen der Digitalisierung auf generelle Unternehmensprozesse,
                  um eine erste Einschätzung der eigenen Digitalisierungsbedarfe und -potenziale zu ermöglichen und ein Bild über
                  den eigenen Fortschritt zu erlangen.
                </div>
                <ButtonRed url={'/keyfacts'} content={'Digitalisierungscheck starten'} />
              </Col>
            </Row>
          </div>
        }
      </div >
    );
  }
}
