import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Jumbotron, ProgressBar, Row} from 'react-bootstrap';

import chevronForward from '../../../assets/icons/circle_chevron@3x.png';
import finishImage from '../../../assets/images/finish-line@3x.png';
import AuthContext from '../../../context/AuthContext/authContext';
import QuestionnaireService from '../../../services/QuestionnaireService';
import {Analytics} from '../../../util/Analytics';
import {useEffectOnInit} from '../../../util/helperFunctions';

import ButtonAbort from '../../common/button/ButtonAbort';
import ButtonTransparent from '../../common/button/ButtonTransparent';
import {EHttpStatusCode} from '../../common/enums/EHttpStatusCode';
import LoadingSpinner from '../../common/loading-spinner/LoadingSpinner';
import ResetQuestionnaireModal from '../../modal/ResetQuestionnaireModal';

import {uncheckedTopicExisting} from '../QuestionnaireUtil';
import Wizard from '../wizard/Wizard';

import './Summary.scss';


export default function Summary(props: ISummaryProps) {
  const questionnaireUuid = props.match.params.uuid;
  const [previousTopicUuid, setPreviousTopicUuid] = useState('');
  const [questionnaire, setQuestionnaire] = useState({} as IQuestionnaire);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const {authenticated, initRegistration, isLoading} = useContext(AuthContext);
  const [showModalReset, setShowModalReset] = useState(false);


  useEffectOnInit(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('lastPage', window.location.pathname);
    checkRedirect();
    Analytics.getInstance().onQuestionnaireDone();
    loadQuestionnaire();
  });

  useEffect(() => {
    checkRedirect();
  }, [isLoading]);

  const registrationDone = (): boolean => {
    const registrationTriggered = localStorage.getItem('registrationTriggered');
    return !!registrationTriggered;
  };

  const checkRedirect = () => {
    if (registrationDone() && !isLoading) {
      completeQuestionnaire();
    }
  };

  const loadQuestionnaire = () => {
    if (questionnaireUuid === 'null') {
      const digiCheckStateItem = localStorage.getItem('digiCheckState');
      if (digiCheckStateItem) {
        const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
        setQuestionnaire(digiCheckState.questionnaire);
      }
    } else if (authenticated) {
      QuestionnaireService.getQuestionnaireById(questionnaireUuid)
        .then(res => {
          const questionnaireFromBackend: IQuestionnaire = res.data;
          const categoryLength = res.data.categories.length;
          const topicLength = res.data.categories[categoryLength - 1].topics.length;
          const previousTopic = res.data.categories[categoryLength - 1].topics[topicLength - 1];

          if (uncheckedTopicExisting(questionnaireFromBackend)[0]) {
            props.history.push('/questionnaire/' + questionnaireUuid + '/topics/' + uncheckedTopicExisting(questionnaireFromBackend)[1]);
          }
          setQuestionnaire(questionnaireFromBackend);
          setPreviousTopicUuid(previousTopic.uuid);
        })
        .catch(err => {
          if (err.response.status === EHttpStatusCode.FORBIDDEN) {
            console.error(err.message + ' Unauthorized user in GET /questionnaires/{uuid}');
          } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
            console.error(err.message + ' Questionnaire not found in GET /questionnaires/{uuid}');
          } else {
            console.error(err.message + ' in GET /questionnaires/{uuid}');
          }
        });
    }
  };

  const completeQuestionnaire = () => {
    const digiCheckStateItem = localStorage.getItem('digiCheckState');
    if (digiCheckStateItem) {
      const digiCheckState: IDigiCheckState = JSON.parse(digiCheckStateItem);
      if (!authenticated) {
        initRegistration();
        localStorage.setItem('registrationTriggered', 'true');
      } else {
        setIsButtonClicked(true);
        QuestionnaireService.postQuestionnaireComplete(digiCheckState)
          .then(res => {
            Analytics.getInstance().onToSummaryClick();
            const completedQuestionnaire: IQuestionnaire = res.data;
            localStorage.removeItem('digiCheckState');
            localStorage.removeItem('registrationTriggered');
            props.history.push('/questionnaire/' + completedQuestionnaire.uuid + '/result');
          })
          .catch(err => {
            setIsButtonClicked(false);
            if (err.response.stats === EHttpStatusCode.NOT_ACCEPTABLE) {
              console.error(err.message + ' Validation exception in POST /questionnaires/complete');
            } else {
              console.error(err.message + ' in POST /questionnaires/complete');
            }
          });
      }
    } else {
      setIsButtonClicked(true);
      QuestionnaireService.putQuestionnaireComplete(questionnaireUuid)
        .then(res => {
          const completedQuestionnaire: IQuestionnaire = res.data;
          const path = `/questionnaire/${completedQuestionnaire.uuid}/result`;
          localStorage.setItem('lastPage', path);
          props.history.push(path);
        })
        .catch(err => {
          setIsButtonClicked(false);

          if (err.response.status === EHttpStatusCode.FORBIDDEN) {
            console.error(err.message + ' Unauthorized user in PUT /questionnaires/{uuid}/complete');
          } else if (err.response.status === EHttpStatusCode.NOT_FOUND) {
            console.error(err.message + ' Questionnaire not found in PUT /questionnaires/{uuid}/complete');
          } else if (err.response.stats === EHttpStatusCode.NOT_ACCEPTABLE) {
            console.error(err.message + ' Validation exception in PUT /questionnaires/{uuid}/complete');
          } else {
            console.error(err.message + ' in PUT /questionnaires/{uuid}');
          }
        });
    }
  };

  if (Object.keys(questionnaire).length !== 0) {

    return (
      !isLoading ?
      <div>
        <Wizard categories={questionnaire.categories} currentCategoryIndex={questionnaire.categories.length - 1} finished={true} />

        <ProgressBar now={100} />
        <Jumbotron>
          <p className='progress-label'>Fortschritt: {100}%</p>
          <div className='col-xs-1 text-center'>
            <h1 className='done-header'>Fast geschafft!</h1>
            <img className='mt-3' height={160} src={finishImage} alt=''></img>
          </div>
          <p className='done-text mx-sm-3 mt-5'>
            Super, Sie haben Ihren Digitalisierungs-Check erfolgreich durchgeführt.
            Es fehlt nur noch ein Schritt zur Auswertung.
            Bitte beachten Sie, dass Sie Ihre Angaben nach der Auswertung nicht mehr ändern können.
            Sie können aber jederzeit einen neuen Digitalisierungs-Check durchführen.
          </p>
        </Jumbotron>

        <Row>
          <Col md={{order: 1, span: 6}} xs={{order: 2, span: 12}} className='mt-5 mt-sm-0'>
            <ButtonAbort setShowModal={setShowModalReset}/>
          </Col>
          <Col md={{order: 2, span: 6}} xs={{order: 1, span: 12}} className='text-md-right oposite-side-buttons'>
            <ButtonTransparent url={`/questionnaire/${questionnaireUuid}/topics/${previousTopicUuid}`}
                               content='Zurück' summary={true}/>
            <Button
              variant='danger'
              className='default-button'
              onClick={completeQuestionnaire}
              disabled={isButtonClicked}
            >
              Weiter zur Auswertung
              <img src={chevronForward} className='chevron-forward' alt='Weiter zur Auswertung' />
            </Button>
          </Col>
        </Row>
        <ResetQuestionnaireModal showModal={showModalReset} setShowModal={setShowModalReset} history={props.history}></ResetQuestionnaireModal>
      </div>
        :
        <LoadingSpinner text='Lade Fragebogen'/>
    );
  }
  return (
    <LoadingSpinner text='Lade Zusammenfassung' />
  );
}
