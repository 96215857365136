import axios, {AxiosResponse} from 'axios';

class SolutionPartnerService {

  getFavorites = async (): Promise<AxiosResponse> => {
    return axios.get(process.env.REACT_APP_API_BASE + '/favorites/solution-partners');
  }

  postFavorite = async (uuid: string, questionnaireUuid: string): Promise<AxiosResponse> => {
    return axios.post(process.env.REACT_APP_API_BASE + '/favorites/solution-partners/' + uuid + '/questionnaire/' + questionnaireUuid);
  }

  deleteFavorite =  async (uuid: string): Promise<AxiosResponse> => {
    return axios.delete(process.env.REACT_APP_API_BASE + '/favorites/solution-partners/' + uuid);
  }

}
export default new SolutionPartnerService();
