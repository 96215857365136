import {useEffect} from 'react';

/**
 * like useEffect
 * but only runs once at the first render
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useEffectOnInit = (func: () => void) => useEffect(func, []);

/**
 * Makes a shallow copy of the given IProfile object and returns it
 * @param oldProfile
 */
export const copyProfile = (oldProfile: IProfile): IProfile => {
  return {
    numberEmployees: oldProfile.numberEmployees,
    operatingSystems: oldProfile.operatingSystems,
    companyName: oldProfile.companyName,
    industry: oldProfile.industry,
  };
};

/**
 * Makes a shallow copy of the given IQuestionnaire object and returns it
 * @param oldQuestionnaire
 */
export const copyQuestionnaire = (oldQuestionnaire: IQuestionnaire): IQuestionnaire => {
  return {
    uuid: oldQuestionnaire.uuid,
    name: oldQuestionnaire.name,
    completed: oldQuestionnaire.completed,
    gradeType: oldQuestionnaire.gradeType,
    totalScore: oldQuestionnaire.totalScore,
    scorings: oldQuestionnaire.scorings,
    categories: oldQuestionnaire.categories,
    solutionPartnerRecommendations: oldQuestionnaire.solutionPartnerRecommendations,
    created: oldQuestionnaire.created,
    lastModified: oldQuestionnaire.lastModified,
    furtherInterests: oldQuestionnaire.furtherInterests,
    averageScore: oldQuestionnaire.averageScore,
  };
};
