import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

export default class ProfileKeyfact extends Component<IProfileKeyfactProps> {
  render() {
    return (
      <Row>
        <Col xs={8} md={6}>
          <p className='profile-text-light'>{this.props.keyfactName}</p>
        </Col>
        <Col xs={4} md={6} className='pl-0'>
          <p className='profile-text'>{this.props.keyfactValue}</p>
        </Col>
      </Row>
    );
  }
}
