import './LastQuestionnaire.scss';
import { Link } from 'react-router-dom';
import chevronIcon from 'assets/icons/circle_chevron_red@3x.png';
import { Component } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { uncheckedTopicExisting } from 'components/questionnaire/QuestionnaireUtil';


export default class LastQuestionnaire extends Component<ILastQuestionnaireProps> {

  handleShowQuestionnaire() {
    if (this.props.questionnaire.completed) {
      const path = '/questionnaire/' + this.props.questionnaire.uuid + '/topics/' + this.props.questionnaire.categories[0].topics[0].uuid;
      this.props.history.push(path);
    } else if (uncheckedTopicExisting(this.props.questionnaire)) {
      const path = '/questionnaire/' + this.props.questionnaire.uuid + '/topics/' + uncheckedTopicExisting(this.props.questionnaire)[1];
      this.props.history.push(path);
    }
  }

  handleShowQuestionnaireResult() {
    this.props.history.push('/questionnaire/' + this.props.questionnaire.uuid + '/result');
  }

  calculatePercentageScore() {
    const percentageScore: number = Number((this.props.questionnaire.totalScore / 100).toFixed(1));
    return percentageScore.toLocaleString(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  }

  renderScore() {
    if (this.props.questionnaire.completed) {
      return (
        <p className='last-questionnaire'>
          <span className='d-md-none'>Ihr Digitalisierungsgrad: </span>
          {this.calculatePercentageScore()} von 4,0
        </p>
      );
    }

    return (<p className='last-questionnaire'><span className='d-lg-none'>Ihr Digitalisierungsgrad: </span>nicht abgeschlossen</p>);
  }

  render() {
    return (
      <div className='last-questionnaire-wrapper'>
        <Row className='mb-4'>
          <Col xs={12} lg={5}>
            <Link to={`questionnaire/${this.props.questionnaire.uuid}/result`}>{this.props.questionnaire.name}</Link>
          </Col>
          <Col className='d-none d-lg-flex' lg={3}>
            <p className='last-questionnaire'>Ihr Digitalisierungsgrad:</p>
          </Col>
          <Col xs={12} lg={3} className='pr-0'>
            {this.renderScore()}
          </Col>
          <Col xs={12} lg={1}>
            <div onClick={this.handleShowQuestionnaireResult.bind(this)}>
              <span className='d-lg-none'>Zur Auswertung </span>
              <Image className='chevron-icon' src={chevronIcon}  />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
