import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Component } from 'react';
import chevronBackward from 'assets/icons/chevron-circle-right@3x.png';


export default class ButtonTopic extends Component<IButtonProps> {
  render() {
    return (
      <Link to={{ pathname: this.props.url, search: '?edit=true' }}>
        <Button variant='light' className='ml-sm-3 mt-xs-3'>
          <img className='chevron-backward' src={chevronBackward} alt='Themenbereiche ändern' />
          Themenbereiche ändern
        </Button>
      </Link>
    );
  }
}
