import React from 'react';
import {KeyCloakService} from 'services/KeyCloakService';

const AuthContext = React.createContext({
  keyCloakProfile: {} as IKeyCloakProfile,
  accessToken: '',
  refreshToken: '',
  authenticated: false,
  keyCloakService: new KeyCloakService(),
  isLoading: true,
  showLoginFailedAlert: false,
  showEmailSent: false,
  showRegistrationFailedAlert: false,


  handleShowLoginAlertChange: (value: boolean) => {},
  handleShowEmailSentChange: (value: boolean) => {},
  initLogin: () => {
  },
  initRegistration: () => {
  },
  updateToken: (): any => {
  },
  login: (username: string, password: string, history: any) => {
  },
  getActualUserAccount: (token: string) => {
  },
  register: (user: IUserRepresentation, history: any) => {
  },
  logout: () => {
  },
  setPrefilledContactUrl: () => {
  },
  getFullName: (): string => {
    return '';
  },
  resetPassword: (email: string) => {
  },
  getLastName: () => {
  },
  getKeyCloakAccount: () => {
    return {} as IKeyCloakProfile;
  },
});

export default AuthContext;
