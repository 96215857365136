import './FurtherSolutionPartnerList.scss';
import plusIcon from 'assets/icons/plus@3x.png';
import { Col, Row } from 'react-bootstrap';
import { Component } from 'react';
import FurtherSolutionPartnerItem from './FurtherSolutionPartnerItem';

export default class FurtherSolutionPartnerList extends Component<ISolutionPartnerListProps> {

  renderFurtherSolutionPartners() {
    const { questionnaire, numberTopSolutionPartners } = this.props;

    if (questionnaire.solutionPartnerRecommendations === undefined
      || questionnaire.solutionPartnerRecommendations.length <= numberTopSolutionPartners) {
      return (null);
    }

    const solutionPartnersGroupByTopics = {};

    questionnaire.solutionPartnerRecommendations.forEach((solutionPartner, index) => {
      if (index < numberTopSolutionPartners) {
        return;
      }

      if (solutionPartnersGroupByTopics[solutionPartner.topic.name] === undefined) {
        solutionPartnersGroupByTopics[solutionPartner.topic.name] = [];
      }

      solutionPartnersGroupByTopics[solutionPartner.topic.name].push(solutionPartner);
    });

    return Object.keys(solutionPartnersGroupByTopics).map((key, index) => {
      const solutionPartnerItems = solutionPartnersGroupByTopics[key].map(solutionPartner => {
        return (
          <FurtherSolutionPartnerItem key={`further-solution-partner-item-${solutionPartner.uuid}`}
            eventKey={index} solutionPartner={solutionPartner} onFavoriteChange={this.props.onFavoriteChange}
            onPartnerClick={this.props.onPartnerClick} />
        );
      });

      return (
        <div className='panel panel-wrapper' key={`further-solution-partner-topic-${index}`}>
          <a className='panel-title collapsed' data-toggle='collapse' href={`#collapse-${index}`}>
          <Row>
            <Col xs={2} sm={1} className='toggler-wrapper'>
              <div className='icon-wrapper'>
                <img src={plusIcon} alt='Öffnen / Schließen' />
              </div>
            </Col>
            <Col xs={10} sm={11} className='headline-wrapper'>
              <span className='headline'>{key}</span>
            </Col>
          </Row>
          </a>
          <div className='panel-body'>
            {solutionPartnerItems}
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className='result-further-solution-partner-wrapper panel-group' id='accordion'>
        <Row>
          <Col>
            <h2>Weitere Empfehlungen für Sie</h2>
          </Col>
        </Row>
        {this.renderFurtherSolutionPartners()}
      </div>
    );
  }
}
