import { Component } from 'react';
import { Spinner } from 'react-bootstrap';

export default class LoadingSpinner extends Component<ILoadingSpinnerProps> {
  render() {
    return (
      <div className='text-center'>
        <Spinner animation='border' variant='danger' className='mb-3' />
        <p>{this.props.text}</p>
      </div>
    );
  }
}
